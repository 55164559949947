export class RoleId {
  private static readonly _values: RoleId[] = [];

  static readonly USER_VIEWER = new RoleId(0, '閲覧者');
  static readonly USER_EDITOR = new RoleId(90, '編集者');
  static readonly USER_ADMIN = new RoleId(100, '管理者');

  private constructor(readonly value: number, readonly text: string) {
    RoleId._values.push(this);
  }

  static fromRoleId(value: number): RoleId {
    const res = RoleId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return RoleId.USER_VIEWER;
    }
  }

  static values(): RoleId[] {
    return RoleId._values;
  }
}
