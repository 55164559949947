import { Section2GHSItemId } from './Section2GHSItemId';

export class Section2GHSCategoryId {
  private static readonly _values: Section2GHSCategoryId[] = [];

  static readonly DEFAULT = new Section2GHSCategoryId(
    0,
    '分類できない・該当データなし ; Classification not possible (No data)',
    '分類できない・該当データなし',
    'Classification not possible (No data)',
    0
  );
  static readonly NOT_CATEGORIZABLE = new Section2GHSCategoryId(
    9999999,
    '区分外 ; Not classified',
    '区分外',
    'Not classified',
    0
  );
  static readonly EXPLOSIVES_UNSTABLE_EXPLOSIVE = new Section2GHSCategoryId(
    2000000,
    '不安定爆発物 ; Unstable Explosive',
    '不安定爆発物',
    'Unstable Explosive',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly EXPLOSIVES_DIVISION1_1 = new Section2GHSCategoryId(
    2000001,
    '等級 1.1 ; Division 1.1',
    '等級 1.1',
    'Division 1.1',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly EXPLOSIVES_DIVISION1_2 = new Section2GHSCategoryId(
    2000002,
    '等級 1.2 ; Division 1.2',
    '等級 1.2',
    'Division 1.2',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly EXPLOSIVES_DIVISION1_3 = new Section2GHSCategoryId(
    2000003,
    '等級 1.3 ; Division 1.3',
    '等級 1.3',
    'Division 1.3',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly EXPLOSIVES_DIVISION1_4 = new Section2GHSCategoryId(
    2000004,
    '等級 1.4 ; Division 1.4',
    '等級 1.4',
    'Division 1.4',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly EXPLOSIVES_DIVISION1_5 = new Section2GHSCategoryId(
    2000005,
    '等級 1.5 ; Division 1.5',
    '等級 1.5',
    'Division 1.5',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly EXPLOSIVES_DIVISION1_6 = new Section2GHSCategoryId(
    2000006,
    '等級 1.6 ; Division 1.6',
    '等級 1.6',
    'Division 1.6',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_1 = new Section2GHSCategoryId(
    2001000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_1A = new Section2GHSCategoryId(
    2001001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_1B = new Section2GHSCategoryId(
    2001002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_1C = new Section2GHSCategoryId(
    2001003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_2 = new Section2GHSCategoryId(
    2001004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_2A = new Section2GHSCategoryId(
    2001005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_2B = new Section2GHSCategoryId(
    2001006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_2C = new Section2GHSCategoryId(
    2001007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_3 = new Section2GHSCategoryId(
    2001008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_3A = new Section2GHSCategoryId(
    2001009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_3B = new Section2GHSCategoryId(
    2001010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_3C = new Section2GHSCategoryId(
    2001011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_4 = new Section2GHSCategoryId(
    2001012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_4A = new Section2GHSCategoryId(
    2001013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_4B = new Section2GHSCategoryId(
    2001014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_4C = new Section2GHSCategoryId(
    2001015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_5 = new Section2GHSCategoryId(
    2001016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_5A = new Section2GHSCategoryId(
    2001017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_5B = new Section2GHSCategoryId(
    2001018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_5C = new Section2GHSCategoryId(
    2001019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly AEROSOLS_CATEGORY_1 = new Section2GHSCategoryId(
    2002000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_1A = new Section2GHSCategoryId(
    2002001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_1B = new Section2GHSCategoryId(
    2002002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_1C = new Section2GHSCategoryId(
    2002003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_2 = new Section2GHSCategoryId(
    2002004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_2A = new Section2GHSCategoryId(
    2002005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_2B = new Section2GHSCategoryId(
    2002006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_2C = new Section2GHSCategoryId(
    2002007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_3 = new Section2GHSCategoryId(
    2002008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_3A = new Section2GHSCategoryId(
    2002009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_3B = new Section2GHSCategoryId(
    2002010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_3C = new Section2GHSCategoryId(
    2002011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_4 = new Section2GHSCategoryId(
    2002012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_4A = new Section2GHSCategoryId(
    2002013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_4B = new Section2GHSCategoryId(
    2002014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_4C = new Section2GHSCategoryId(
    2002015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_5 = new Section2GHSCategoryId(
    2002016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_5A = new Section2GHSCategoryId(
    2002017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_5B = new Section2GHSCategoryId(
    2002018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_5C = new Section2GHSCategoryId(
    2002019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_1 = new Section2GHSCategoryId(
    2003000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_1A = new Section2GHSCategoryId(
    2003001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_1B = new Section2GHSCategoryId(
    2003002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_1C = new Section2GHSCategoryId(
    2003003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_2 = new Section2GHSCategoryId(
    2003004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_2A = new Section2GHSCategoryId(
    2003005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_2B = new Section2GHSCategoryId(
    2003006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_2C = new Section2GHSCategoryId(
    2003007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_3 = new Section2GHSCategoryId(
    2003008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_3A = new Section2GHSCategoryId(
    2003009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_3B = new Section2GHSCategoryId(
    2003010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_3C = new Section2GHSCategoryId(
    2003011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_4 = new Section2GHSCategoryId(
    2003012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_4A = new Section2GHSCategoryId(
    2003013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_4B = new Section2GHSCategoryId(
    2003014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_4C = new Section2GHSCategoryId(
    2003015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_5 = new Section2GHSCategoryId(
    2003016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_5A = new Section2GHSCategoryId(
    2003017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_5B = new Section2GHSCategoryId(
    2003018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_5C = new Section2GHSCategoryId(
    2003019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly GASES_UNDER_PRESSURE_COMPRESSEDGAS =
    new Section2GHSCategoryId(
      2004000,
      '圧縮ガス ; Compressed gas',
      '圧縮ガス',
      'Compressed gas',
      Section2GHSItemId.GASES_UNDER_PRESSURE.value
    );
  static readonly GASES_UNDER_PRESSURE_LIQUEFIEDGAS = new Section2GHSCategoryId(
    2004001,
    '液化ガス ; Liquefied gas',
    '液化ガス',
    'Liquefied gas',
    Section2GHSItemId.GASES_UNDER_PRESSURE.value
  );
  static readonly GASES_UNDER_PRESSURE_REFRIGERATEDLIQUEFIEDGAS =
    new Section2GHSCategoryId(
      2004002,
      '深冷液化ガス ; Refrigerated liquefied gas',
      '深冷液化ガス',
      'Refrigerated liquefied gas',
      Section2GHSItemId.GASES_UNDER_PRESSURE.value
    );
  static readonly GASES_UNDER_PRESSURE_DISSOLVEDGAS = new Section2GHSCategoryId(
    2004003,
    '溶解ガス ; Dissolved gas',
    '溶解ガス',
    'Dissolved gas',
    Section2GHSItemId.GASES_UNDER_PRESSURE.value
  );
  static readonly GASES_UNDER_PRESSURE_HIGH_PRESSURE =
    new Section2GHSCategoryId(
      2004004,
      '高圧ガス ; High Pressure gas',
      '高圧ガス',
      'High Pressure gas',
      Section2GHSItemId.GASES_UNDER_PRESSURE.value
    );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_1 = new Section2GHSCategoryId(
    2005000,
    '区分1 ; Category1',
    'Category1',
    'Category1',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_1A = new Section2GHSCategoryId(
    2005001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_1B = new Section2GHSCategoryId(
    2005002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_1C = new Section2GHSCategoryId(
    2005003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_2 = new Section2GHSCategoryId(
    2005004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_2A = new Section2GHSCategoryId(
    2005005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_2B = new Section2GHSCategoryId(
    2005006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_2C = new Section2GHSCategoryId(
    2005007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_3 = new Section2GHSCategoryId(
    2005008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_3A = new Section2GHSCategoryId(
    2005009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_3B = new Section2GHSCategoryId(
    2005010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_3C = new Section2GHSCategoryId(
    2005011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_4 = new Section2GHSCategoryId(
    2005012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_4A = new Section2GHSCategoryId(
    2005013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_4B = new Section2GHSCategoryId(
    2005014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_4C = new Section2GHSCategoryId(
    2005015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_5 = new Section2GHSCategoryId(
    2005016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_5A = new Section2GHSCategoryId(
    2005017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_5B = new Section2GHSCategoryId(
    2005018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_5C = new Section2GHSCategoryId(
    2005019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_1 = new Section2GHSCategoryId(
    2006000,
    '区分1 ; Category1',
    'Category1',
    'Category1',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_1A = new Section2GHSCategoryId(
    2006001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_1B = new Section2GHSCategoryId(
    2006002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_1C = new Section2GHSCategoryId(
    2006003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_2 = new Section2GHSCategoryId(
    2006004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_2A = new Section2GHSCategoryId(
    2006005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_2B = new Section2GHSCategoryId(
    2006006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_2C = new Section2GHSCategoryId(
    2006007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_3 = new Section2GHSCategoryId(
    2006008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_3A = new Section2GHSCategoryId(
    2006009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_3B = new Section2GHSCategoryId(
    2006010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_3C = new Section2GHSCategoryId(
    2006011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_4 = new Section2GHSCategoryId(
    2006012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_4A = new Section2GHSCategoryId(
    2006013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_4B = new Section2GHSCategoryId(
    2006014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_4C = new Section2GHSCategoryId(
    2006015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_5 = new Section2GHSCategoryId(
    2006016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_5A = new Section2GHSCategoryId(
    2006017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_5B = new Section2GHSCategoryId(
    2006018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_5C = new Section2GHSCategoryId(
    2006019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_A =
    new Section2GHSCategoryId(
      2007000,
      'タイプ A ; TypeA',
      'タイプ A',
      'TypeA',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_B =
    new Section2GHSCategoryId(
      2007001,
      'タイプ B ; TypeB',
      'タイプ B',
      'TypeB',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_C =
    new Section2GHSCategoryId(
      2007002,
      'タイプ C ; TypeC',
      'タイプ C',
      'TypeC',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_D =
    new Section2GHSCategoryId(
      2007003,
      'タイプ D ; TypeD',
      'タイプ D',
      'TypeD',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_E =
    new Section2GHSCategoryId(
      2007004,
      'タイプ E ; TypeE',
      'タイプ E',
      'TypeE',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_F =
    new Section2GHSCategoryId(
      2007005,
      'タイプ F ; TypeF',
      'タイプ F',
      'TypeF',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_G =
    new Section2GHSCategoryId(
      2007006,
      'タイプ G ; TypeG',
      'タイプ G',
      'TypeG',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_1 = new Section2GHSCategoryId(
    2008000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_1A = new Section2GHSCategoryId(
    2008001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_1B = new Section2GHSCategoryId(
    2008002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_1C = new Section2GHSCategoryId(
    2008003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_2 = new Section2GHSCategoryId(
    2008004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_2A = new Section2GHSCategoryId(
    2008005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_2B = new Section2GHSCategoryId(
    2008006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_2C = new Section2GHSCategoryId(
    2008007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_3 = new Section2GHSCategoryId(
    2008008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_3A = new Section2GHSCategoryId(
    2008009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_3B = new Section2GHSCategoryId(
    2008010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_3C = new Section2GHSCategoryId(
    2008011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_4 = new Section2GHSCategoryId(
    2008012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_4A = new Section2GHSCategoryId(
    2008013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_4B = new Section2GHSCategoryId(
    2008014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_4C = new Section2GHSCategoryId(
    2008015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_5 = new Section2GHSCategoryId(
    2008016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_5A = new Section2GHSCategoryId(
    2008017,
    '区分5 ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_5B = new Section2GHSCategoryId(
    2008018,
    '区分5 ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_5C = new Section2GHSCategoryId(
    2008019,
    '区分5 ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_1 = new Section2GHSCategoryId(
    2009000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_1A = new Section2GHSCategoryId(
    2009001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_1B = new Section2GHSCategoryId(
    2009002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_1C = new Section2GHSCategoryId(
    2009003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_2 = new Section2GHSCategoryId(
    2009004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_2A = new Section2GHSCategoryId(
    2009005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_2B = new Section2GHSCategoryId(
    2009006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_2C = new Section2GHSCategoryId(
    2009007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_3 = new Section2GHSCategoryId(
    2009008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_3A = new Section2GHSCategoryId(
    2009009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_3B = new Section2GHSCategoryId(
    2009010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_3C = new Section2GHSCategoryId(
    2009011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_4 = new Section2GHSCategoryId(
    2009012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_4A = new Section2GHSCategoryId(
    2009013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_4B = new Section2GHSCategoryId(
    2009014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_4C = new Section2GHSCategoryId(
    2009015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_5 = new Section2GHSCategoryId(
    2009016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_5A = new Section2GHSCategoryId(
    2009017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_5B = new Section2GHSCategoryId(
    2009018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_5C = new Section2GHSCategoryId(
    2009019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_1 =
    new Section2GHSCategoryId(
      2010000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_1A =
    new Section2GHSCategoryId(
      2010001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_1B =
    new Section2GHSCategoryId(
      2010002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_1C =
    new Section2GHSCategoryId(
      2010003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_2 =
    new Section2GHSCategoryId(
      2010004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_2A =
    new Section2GHSCategoryId(
      2010005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_2B =
    new Section2GHSCategoryId(
      2010006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_2C =
    new Section2GHSCategoryId(
      2010007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_3 =
    new Section2GHSCategoryId(
      2010008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_3A =
    new Section2GHSCategoryId(
      2010009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_3B =
    new Section2GHSCategoryId(
      2010010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_3C =
    new Section2GHSCategoryId(
      2010011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_4 =
    new Section2GHSCategoryId(
      2010012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_4A =
    new Section2GHSCategoryId(
      2010013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_4B =
    new Section2GHSCategoryId(
      2010014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_4C =
    new Section2GHSCategoryId(
      2010015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_5 =
    new Section2GHSCategoryId(
      2010016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_5A =
    new Section2GHSCategoryId(
      2010017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_5B =
    new Section2GHSCategoryId(
      2010018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_5C =
    new Section2GHSCategoryId(
      2010019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_1 =
    new Section2GHSCategoryId(
      2011000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_1A =
    new Section2GHSCategoryId(
      2011001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_1B =
    new Section2GHSCategoryId(
      2011002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_1C =
    new Section2GHSCategoryId(
      2011003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_2 =
    new Section2GHSCategoryId(
      2011004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_2A =
    new Section2GHSCategoryId(
      2011005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_2B =
    new Section2GHSCategoryId(
      2011006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_2C =
    new Section2GHSCategoryId(
      2011007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_3 =
    new Section2GHSCategoryId(
      2011008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_3A =
    new Section2GHSCategoryId(
      2011009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_3B =
    new Section2GHSCategoryId(
      2011010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_3C =
    new Section2GHSCategoryId(
      2011011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_4 =
    new Section2GHSCategoryId(
      2011012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_4A =
    new Section2GHSCategoryId(
      2011013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_4B =
    new Section2GHSCategoryId(
      2011014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_4C =
    new Section2GHSCategoryId(
      2011015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_5 =
    new Section2GHSCategoryId(
      2011016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_5A =
    new Section2GHSCategoryId(
      2011017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_5B =
    new Section2GHSCategoryId(
      2011018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_5C =
    new Section2GHSCategoryId(
      2011019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_1 = new Section2GHSCategoryId(
    2012000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_1A = new Section2GHSCategoryId(
    2012001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_1B = new Section2GHSCategoryId(
    2012002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_1C = new Section2GHSCategoryId(
    2012003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_2 = new Section2GHSCategoryId(
    2012004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_2A = new Section2GHSCategoryId(
    2012005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_2B = new Section2GHSCategoryId(
    2012006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_2C = new Section2GHSCategoryId(
    2012007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_3 = new Section2GHSCategoryId(
    2012008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_3A = new Section2GHSCategoryId(
    2012009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_3B = new Section2GHSCategoryId(
    2012010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_3C = new Section2GHSCategoryId(
    2012011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_4 = new Section2GHSCategoryId(
    2012012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_4A = new Section2GHSCategoryId(
    2012013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_4B = new Section2GHSCategoryId(
    2012014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_4C = new Section2GHSCategoryId(
    2012015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_5 = new Section2GHSCategoryId(
    2012016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_5A = new Section2GHSCategoryId(
    2012017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_5B = new Section2GHSCategoryId(
    2012018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_5C = new Section2GHSCategoryId(
    2012019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_1 = new Section2GHSCategoryId(
    2013000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_1A = new Section2GHSCategoryId(
    2013001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_1B = new Section2GHSCategoryId(
    2013002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_1C = new Section2GHSCategoryId(
    2013003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_2 = new Section2GHSCategoryId(
    2013004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_2A = new Section2GHSCategoryId(
    2013005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_2B = new Section2GHSCategoryId(
    2013006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_2C = new Section2GHSCategoryId(
    2013007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_3 = new Section2GHSCategoryId(
    2013008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_3A = new Section2GHSCategoryId(
    2013009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_3B = new Section2GHSCategoryId(
    2013010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_3C = new Section2GHSCategoryId(
    2013011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_4 = new Section2GHSCategoryId(
    2013012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_4A = new Section2GHSCategoryId(
    2013013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_4B = new Section2GHSCategoryId(
    2013014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_4C = new Section2GHSCategoryId(
    2013015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_5 = new Section2GHSCategoryId(
    2013016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_5A = new Section2GHSCategoryId(
    2013017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_5B = new Section2GHSCategoryId(
    2013018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_5C = new Section2GHSCategoryId(
    2013019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly ORGANIC_PEROXIDES_TYPEA = new Section2GHSCategoryId(
    2014000,
    'タイプ A ; TypeA',
    'タイプ A',
    'TypeA',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly ORGANIC_PEROXIDES_TYPEB = new Section2GHSCategoryId(
    2014001,
    'タイプ B ; TypeB',
    'タイプ B',
    'TypeB',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly ORGANIC_PEROXIDES_TYPEC = new Section2GHSCategoryId(
    2014002,
    'タイプ C ; TypeC',
    'タイプ C',
    'TypeC',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly ORGANIC_PEROXIDES_TYPED = new Section2GHSCategoryId(
    2014003,
    'タイプ D ; TypeD',
    'タイプ D',
    'TypeD',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly ORGANIC_PEROXIDES_TYPEE = new Section2GHSCategoryId(
    2014004,
    'タイプ E ; TypeE',
    'タイプ E',
    'TypeE',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly ORGANIC_PEROXIDES_TYPEF = new Section2GHSCategoryId(
    2014005,
    'タイプ F ; TypeF',
    'タイプ F',
    'TypeF',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly ORGANIC_PEROXIDES_TYPEG = new Section2GHSCategoryId(
    2014006,
    'タイプ G ; TypeG',
    'タイプ G',
    'TypeG',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_1 = new Section2GHSCategoryId(
    2015000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_1A = new Section2GHSCategoryId(
    2015001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_1B = new Section2GHSCategoryId(
    2015002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_1C = new Section2GHSCategoryId(
    2015003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_2 = new Section2GHSCategoryId(
    2015004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_2A = new Section2GHSCategoryId(
    2015005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_2B = new Section2GHSCategoryId(
    2015006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_2C = new Section2GHSCategoryId(
    2015007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_3 = new Section2GHSCategoryId(
    2015008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_3A = new Section2GHSCategoryId(
    2015009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_3B = new Section2GHSCategoryId(
    2015010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_3C = new Section2GHSCategoryId(
    2015011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_4 = new Section2GHSCategoryId(
    2015012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_4A = new Section2GHSCategoryId(
    2015013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_4B = new Section2GHSCategoryId(
    2015014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_4C = new Section2GHSCategoryId(
    2015015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_5 = new Section2GHSCategoryId(
    2015016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_5A = new Section2GHSCategoryId(
    2015017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_5B = new Section2GHSCategoryId(
    2015018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_5C = new Section2GHSCategoryId(
    2015019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_1 =
    new Section2GHSCategoryId(
      2016000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_1A =
    new Section2GHSCategoryId(
      2016001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_1B =
    new Section2GHSCategoryId(
      2016002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_1C =
    new Section2GHSCategoryId(
      2016003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_2 =
    new Section2GHSCategoryId(
      2016004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_2A =
    new Section2GHSCategoryId(
      2016005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_2B =
    new Section2GHSCategoryId(
      2016006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_2C =
    new Section2GHSCategoryId(
      2016007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_3 =
    new Section2GHSCategoryId(
      2016008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_3A =
    new Section2GHSCategoryId(
      2016009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_3B =
    new Section2GHSCategoryId(
      2016010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_3C =
    new Section2GHSCategoryId(
      2016011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_4 =
    new Section2GHSCategoryId(
      2016012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_4A =
    new Section2GHSCategoryId(
      2016013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_4B =
    new Section2GHSCategoryId(
      2016014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_4C =
    new Section2GHSCategoryId(
      2016015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_5 =
    new Section2GHSCategoryId(
      2016016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_5A =
    new Section2GHSCategoryId(
      2016017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_5B =
    new Section2GHSCategoryId(
      2016018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_5C =
    new Section2GHSCategoryId(
      2016019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_1 = new Section2GHSCategoryId(
    2017000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_1A = new Section2GHSCategoryId(
    2017001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_1B = new Section2GHSCategoryId(
    2017002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_1C = new Section2GHSCategoryId(
    2017003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_2 = new Section2GHSCategoryId(
    2017004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_2A = new Section2GHSCategoryId(
    2017005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_2B = new Section2GHSCategoryId(
    2017006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_2C = new Section2GHSCategoryId(
    2017007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_3 = new Section2GHSCategoryId(
    2017008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_3A = new Section2GHSCategoryId(
    2017009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_3B = new Section2GHSCategoryId(
    2017010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_3C = new Section2GHSCategoryId(
    2017011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_4 = new Section2GHSCategoryId(
    2017012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_4A = new Section2GHSCategoryId(
    2017013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_4B = new Section2GHSCategoryId(
    2017014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_4C = new Section2GHSCategoryId(
    2017015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_5 = new Section2GHSCategoryId(
    2017016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_5A = new Section2GHSCategoryId(
    2017017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_5B = new Section2GHSCategoryId(
    2017018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_5C = new Section2GHSCategoryId(
    2017019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_1 = new Section2GHSCategoryId(
    2018000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_1A = new Section2GHSCategoryId(
    2018001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_1B = new Section2GHSCategoryId(
    2018002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_1C = new Section2GHSCategoryId(
    2018003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_2 = new Section2GHSCategoryId(
    2018004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_2A = new Section2GHSCategoryId(
    2018005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_2B = new Section2GHSCategoryId(
    2018006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_2C = new Section2GHSCategoryId(
    2018007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_3 = new Section2GHSCategoryId(
    2018008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_3A = new Section2GHSCategoryId(
    2018009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_3B = new Section2GHSCategoryId(
    2018010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_3C = new Section2GHSCategoryId(
    2018011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_4 = new Section2GHSCategoryId(
    2018012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_4A = new Section2GHSCategoryId(
    2018013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_4B = new Section2GHSCategoryId(
    2018014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_4C = new Section2GHSCategoryId(
    2018015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_5 = new Section2GHSCategoryId(
    2018016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_5A = new Section2GHSCategoryId(
    2018017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_5B = new Section2GHSCategoryId(
    2018018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_5C = new Section2GHSCategoryId(
    2018019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_1 =
    new Section2GHSCategoryId(
      2019000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_1A =
    new Section2GHSCategoryId(
      2019001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_1B =
    new Section2GHSCategoryId(
      2019002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_1C =
    new Section2GHSCategoryId(
      2019003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_2 =
    new Section2GHSCategoryId(
      2019004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_2A =
    new Section2GHSCategoryId(
      2019005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_2B =
    new Section2GHSCategoryId(
      2019006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_2C =
    new Section2GHSCategoryId(
      2019007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_3 =
    new Section2GHSCategoryId(
      2019008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_3A =
    new Section2GHSCategoryId(
      2019009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_3B =
    new Section2GHSCategoryId(
      2019010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_3C =
    new Section2GHSCategoryId(
      2019011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_4 =
    new Section2GHSCategoryId(
      2019012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_4A =
    new Section2GHSCategoryId(
      2019013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_4B =
    new Section2GHSCategoryId(
      2019014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_4C =
    new Section2GHSCategoryId(
      2019015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_5 =
    new Section2GHSCategoryId(
      2019016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_5A =
    new Section2GHSCategoryId(
      2019017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_5B =
    new Section2GHSCategoryId(
      2019018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_5C =
    new Section2GHSCategoryId(
      2019019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_1 =
    new Section2GHSCategoryId(
      2020000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_1A =
    new Section2GHSCategoryId(
      2020001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_1B =
    new Section2GHSCategoryId(
      2020002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_1C =
    new Section2GHSCategoryId(
      2020003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_2 =
    new Section2GHSCategoryId(
      2020004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_2A =
    new Section2GHSCategoryId(
      2020005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_2B =
    new Section2GHSCategoryId(
      2020006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_2C =
    new Section2GHSCategoryId(
      2020007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_3 =
    new Section2GHSCategoryId(
      2020008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_3A =
    new Section2GHSCategoryId(
      2020009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_3B =
    new Section2GHSCategoryId(
      2020010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_3C =
    new Section2GHSCategoryId(
      2020011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_4 =
    new Section2GHSCategoryId(
      2020012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_4A =
    new Section2GHSCategoryId(
      2020013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_4B =
    new Section2GHSCategoryId(
      2020014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_4C =
    new Section2GHSCategoryId(
      2020015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_5 =
    new Section2GHSCategoryId(
      2020016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_5A =
    new Section2GHSCategoryId(
      2020017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_5B =
    new Section2GHSCategoryId(
      2020018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_5C =
    new Section2GHSCategoryId(
      2020019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_1 =
    new Section2GHSCategoryId(
      2021000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_1A =
    new Section2GHSCategoryId(
      2021001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_1B =
    new Section2GHSCategoryId(
      2021002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_1C =
    new Section2GHSCategoryId(
      2021003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_2 =
    new Section2GHSCategoryId(
      2021004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_2A =
    new Section2GHSCategoryId(
      2021005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_2B =
    new Section2GHSCategoryId(
      2021006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_2C =
    new Section2GHSCategoryId(
      2021007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_3 =
    new Section2GHSCategoryId(
      2021008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_3A =
    new Section2GHSCategoryId(
      2021009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_3B =
    new Section2GHSCategoryId(
      2021010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_3C =
    new Section2GHSCategoryId(
      2021011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_4 =
    new Section2GHSCategoryId(
      2021012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_4A =
    new Section2GHSCategoryId(
      2021013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_4B =
    new Section2GHSCategoryId(
      2021014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_4C =
    new Section2GHSCategoryId(
      2021015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_5 =
    new Section2GHSCategoryId(
      2021016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_5A =
    new Section2GHSCategoryId(
      2021017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_5B =
    new Section2GHSCategoryId(
      2021018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_5C =
    new Section2GHSCategoryId(
      2021019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_1 =
    new Section2GHSCategoryId(
      2022000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_1A =
    new Section2GHSCategoryId(
      2022001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_1B =
    new Section2GHSCategoryId(
      2022002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_1C =
    new Section2GHSCategoryId(
      2022003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_2 =
    new Section2GHSCategoryId(
      2022004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_2A =
    new Section2GHSCategoryId(
      2022005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_2B =
    new Section2GHSCategoryId(
      2022006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_2C =
    new Section2GHSCategoryId(
      2022007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_3 =
    new Section2GHSCategoryId(
      2022008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_3A =
    new Section2GHSCategoryId(
      2022009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_3B =
    new Section2GHSCategoryId(
      2022010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_3C =
    new Section2GHSCategoryId(
      2022011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_4 =
    new Section2GHSCategoryId(
      2022012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_4A =
    new Section2GHSCategoryId(
      2022013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_4B =
    new Section2GHSCategoryId(
      2022014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_4C =
    new Section2GHSCategoryId(
      2022015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_5 =
    new Section2GHSCategoryId(
      2022016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_5A =
    new Section2GHSCategoryId(
      2022017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_5B =
    new Section2GHSCategoryId(
      2022018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_5C =
    new Section2GHSCategoryId(
      2022019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_1 =
    new Section2GHSCategoryId(
      2023000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_1A =
    new Section2GHSCategoryId(
      2023001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_1B =
    new Section2GHSCategoryId(
      2023002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_1C =
    new Section2GHSCategoryId(
      2023003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_2 =
    new Section2GHSCategoryId(
      2023004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_2A =
    new Section2GHSCategoryId(
      2023005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_2B =
    new Section2GHSCategoryId(
      2023006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_2C =
    new Section2GHSCategoryId(
      2023007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_3 =
    new Section2GHSCategoryId(
      2023008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_3A =
    new Section2GHSCategoryId(
      2023009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_3B =
    new Section2GHSCategoryId(
      2023010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_3C =
    new Section2GHSCategoryId(
      2023011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_4 =
    new Section2GHSCategoryId(
      2023012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_4A =
    new Section2GHSCategoryId(
      2023013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_4B =
    new Section2GHSCategoryId(
      2023014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_4C =
    new Section2GHSCategoryId(
      2023015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_5 =
    new Section2GHSCategoryId(
      2023016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_5A =
    new Section2GHSCategoryId(
      2023017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_5B =
    new Section2GHSCategoryId(
      2023018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_5C =
    new Section2GHSCategoryId(
      2023019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_1 = new Section2GHSCategoryId(
    2024000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_1A = new Section2GHSCategoryId(
    2024001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_1B = new Section2GHSCategoryId(
    2024002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_1C = new Section2GHSCategoryId(
    2024003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_2 = new Section2GHSCategoryId(
    2024004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_2A = new Section2GHSCategoryId(
    2024005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_2B = new Section2GHSCategoryId(
    2024006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_2C = new Section2GHSCategoryId(
    2024007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_3 = new Section2GHSCategoryId(
    2024008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_3A = new Section2GHSCategoryId(
    2024009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_3B = new Section2GHSCategoryId(
    2024010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_3C = new Section2GHSCategoryId(
    2024011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_4 = new Section2GHSCategoryId(
    2024012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_4A = new Section2GHSCategoryId(
    2024013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_4B = new Section2GHSCategoryId(
    2024014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_4C = new Section2GHSCategoryId(
    2024015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_5 = new Section2GHSCategoryId(
    2024016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_5A = new Section2GHSCategoryId(
    2024017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_5B = new Section2GHSCategoryId(
    2024018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_5C = new Section2GHSCategoryId(
    2024019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_1 =
    new Section2GHSCategoryId(
      2025000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_1A =
    new Section2GHSCategoryId(
      2025001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_1B =
    new Section2GHSCategoryId(
      2025002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_1C =
    new Section2GHSCategoryId(
      2025003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_2 =
    new Section2GHSCategoryId(
      2025004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_2A =
    new Section2GHSCategoryId(
      2025005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_2B =
    new Section2GHSCategoryId(
      2025006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_2C =
    new Section2GHSCategoryId(
      2025007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_3 =
    new Section2GHSCategoryId(
      2025008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_3A =
    new Section2GHSCategoryId(
      2025009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_3B =
    new Section2GHSCategoryId(
      2025010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_3C =
    new Section2GHSCategoryId(
      2025011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_4 =
    new Section2GHSCategoryId(
      2025012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_4A =
    new Section2GHSCategoryId(
      2025013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_4B =
    new Section2GHSCategoryId(
      2025014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_4C =
    new Section2GHSCategoryId(
      2025015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_5 =
    new Section2GHSCategoryId(
      2025016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_5A =
    new Section2GHSCategoryId(
      2025017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_5B =
    new Section2GHSCategoryId(
      2025018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_5C =
    new Section2GHSCategoryId(
      2025019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly SKIN_SENSITIZATION_CATEGORY_1 = new Section2GHSCategoryId(
    2026000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_1A = new Section2GHSCategoryId(
    2026001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_1B = new Section2GHSCategoryId(
    2026002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_1C = new Section2GHSCategoryId(
    2026003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_2 = new Section2GHSCategoryId(
    2026004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_2A = new Section2GHSCategoryId(
    2026005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_2B = new Section2GHSCategoryId(
    2026006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_2C = new Section2GHSCategoryId(
    2026007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_3 = new Section2GHSCategoryId(
    2026008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_3A = new Section2GHSCategoryId(
    2026009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_3B = new Section2GHSCategoryId(
    2026010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_3C = new Section2GHSCategoryId(
    2026011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_4 = new Section2GHSCategoryId(
    2026012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_4A = new Section2GHSCategoryId(
    2026013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_4B = new Section2GHSCategoryId(
    2026014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_4C = new Section2GHSCategoryId(
    2026015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_5 = new Section2GHSCategoryId(
    2026016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_5A = new Section2GHSCategoryId(
    2026017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_5B = new Section2GHSCategoryId(
    2026018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_5C = new Section2GHSCategoryId(
    2026019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_1 = new Section2GHSCategoryId(
    2027000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
  );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_1A =
    new Section2GHSCategoryId(
      2027001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_1B =
    new Section2GHSCategoryId(
      2027002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_1C =
    new Section2GHSCategoryId(
      2027003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_2 = new Section2GHSCategoryId(
    2027004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
  );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_2A =
    new Section2GHSCategoryId(
      2027005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_2B =
    new Section2GHSCategoryId(
      2027006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_2C =
    new Section2GHSCategoryId(
      2027007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_3 = new Section2GHSCategoryId(
    2027008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
  );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_3A =
    new Section2GHSCategoryId(
      2027009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_3B =
    new Section2GHSCategoryId(
      2027010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_3C =
    new Section2GHSCategoryId(
      2027011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_4 = new Section2GHSCategoryId(
    2027012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
  );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_4A =
    new Section2GHSCategoryId(
      2027013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_4B =
    new Section2GHSCategoryId(
      2027014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_4C =
    new Section2GHSCategoryId(
      2027015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_5 = new Section2GHSCategoryId(
    2027016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
  );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_5A =
    new Section2GHSCategoryId(
      2027017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_5B =
    new Section2GHSCategoryId(
      2027018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_5C =
    new Section2GHSCategoryId(
      2027019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly CARCINOGENICITY_CATEGORY_1 = new Section2GHSCategoryId(
    2028000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_1A = new Section2GHSCategoryId(
    2028001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_1B = new Section2GHSCategoryId(
    2028002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_1C = new Section2GHSCategoryId(
    2028003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_2 = new Section2GHSCategoryId(
    2028004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_2A = new Section2GHSCategoryId(
    2028005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_2B = new Section2GHSCategoryId(
    2028006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_2C = new Section2GHSCategoryId(
    2028007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_3 = new Section2GHSCategoryId(
    2028008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_3A = new Section2GHSCategoryId(
    2028009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_3B = new Section2GHSCategoryId(
    2028010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_3C = new Section2GHSCategoryId(
    2028011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_4 = new Section2GHSCategoryId(
    2028012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_4A = new Section2GHSCategoryId(
    2028013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_4B = new Section2GHSCategoryId(
    2028014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_4C = new Section2GHSCategoryId(
    2028015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_5 = new Section2GHSCategoryId(
    2028016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_5A = new Section2GHSCategoryId(
    2028017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_5B = new Section2GHSCategoryId(
    2028018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_5C = new Section2GHSCategoryId(
    2028019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_1 = new Section2GHSCategoryId(
    2029000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_1A = new Section2GHSCategoryId(
    2029001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_1B = new Section2GHSCategoryId(
    2029002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_1C = new Section2GHSCategoryId(
    2029003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_2 = new Section2GHSCategoryId(
    2029004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_2A = new Section2GHSCategoryId(
    2029005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_2B = new Section2GHSCategoryId(
    2029006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_2C = new Section2GHSCategoryId(
    2029007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_3 = new Section2GHSCategoryId(
    2029008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_3A = new Section2GHSCategoryId(
    2029009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_3B = new Section2GHSCategoryId(
    2029010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_3C = new Section2GHSCategoryId(
    2029011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_4 = new Section2GHSCategoryId(
    2029012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_4A = new Section2GHSCategoryId(
    2029013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_4B = new Section2GHSCategoryId(
    2029014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_4C = new Section2GHSCategoryId(
    2029015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_5 = new Section2GHSCategoryId(
    2029016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_5A = new Section2GHSCategoryId(
    2029017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_5B = new Section2GHSCategoryId(
    2029018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_5C = new Section2GHSCategoryId(
    2029019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_ADDITIONAL =
    new Section2GHSCategoryId(
      2029020,
      '追加区分 ; Additional Category',
      '追加区分',
      'Additional Category',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly REPRODUCTIVE_TOXICITY_NOT_CATEGORY_ADDITIONAL =
    new Section2GHSCategoryId(
      2029021,
      '追加区分に該当しない; Not classified as Additional Category',
      '追加区分に該当しない',
      'Not classified as Additional Category',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_1 =
    new Section2GHSCategoryId(
      2030000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_1A =
    new Section2GHSCategoryId(
      2030001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_1B =
    new Section2GHSCategoryId(
      2030002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_1C =
    new Section2GHSCategoryId(
      2030003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_2 =
    new Section2GHSCategoryId(
      2030004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_2A =
    new Section2GHSCategoryId(
      2030005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_2B =
    new Section2GHSCategoryId(
      2030006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_2C =
    new Section2GHSCategoryId(
      2030007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_3 =
    new Section2GHSCategoryId(
      2030008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_3A =
    new Section2GHSCategoryId(
      2030009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_3B =
    new Section2GHSCategoryId(
      2030010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_3C =
    new Section2GHSCategoryId(
      2030011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_4 =
    new Section2GHSCategoryId(
      2030012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_4A =
    new Section2GHSCategoryId(
      2030013,
      '区分4 ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_4B =
    new Section2GHSCategoryId(
      2030014,
      '区分4 ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_4C =
    new Section2GHSCategoryId(
      2030015,
      '区分4 ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_5 =
    new Section2GHSCategoryId(
      2030016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_5A =
    new Section2GHSCategoryId(
      2030017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_5B =
    new Section2GHSCategoryId(
      2030018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_5C =
    new Section2GHSCategoryId(
      2030019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_1 =
    new Section2GHSCategoryId(
      2031000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_1A =
    new Section2GHSCategoryId(
      2031001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_1B =
    new Section2GHSCategoryId(
      2031002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_1C =
    new Section2GHSCategoryId(
      2031003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_2 =
    new Section2GHSCategoryId(
      2031004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_2A =
    new Section2GHSCategoryId(
      2031005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_2B =
    new Section2GHSCategoryId(
      2031006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_2C =
    new Section2GHSCategoryId(
      2031007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_3 =
    new Section2GHSCategoryId(
      2031008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_3A =
    new Section2GHSCategoryId(
      2031009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_3B =
    new Section2GHSCategoryId(
      2031010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_3C =
    new Section2GHSCategoryId(
      2031011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_4 =
    new Section2GHSCategoryId(
      2031012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_4A =
    new Section2GHSCategoryId(
      2031013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_4B =
    new Section2GHSCategoryId(
      2031014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_4C =
    new Section2GHSCategoryId(
      2031015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_5 =
    new Section2GHSCategoryId(
      2031016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_5A =
    new Section2GHSCategoryId(
      2031017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_5B =
    new Section2GHSCategoryId(
      2031018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_5C =
    new Section2GHSCategoryId(
      2031019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly ASPIRATION_HAZARD_CATEGORY_1 = new Section2GHSCategoryId(
    2032000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_1A = new Section2GHSCategoryId(
    2032001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_1B = new Section2GHSCategoryId(
    2032002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_1C = new Section2GHSCategoryId(
    2032003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_2 = new Section2GHSCategoryId(
    2032004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_2A = new Section2GHSCategoryId(
    2032005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_2B = new Section2GHSCategoryId(
    2032006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_2C = new Section2GHSCategoryId(
    2032007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_3 = new Section2GHSCategoryId(
    2032008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_3A = new Section2GHSCategoryId(
    2032009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_3B = new Section2GHSCategoryId(
    2032010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_3C = new Section2GHSCategoryId(
    2032011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_4 = new Section2GHSCategoryId(
    2032012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_4A = new Section2GHSCategoryId(
    2032013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_4B = new Section2GHSCategoryId(
    2032014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_4C = new Section2GHSCategoryId(
    2032015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_5 = new Section2GHSCategoryId(
    2032016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_5A = new Section2GHSCategoryId(
    2032017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_5B = new Section2GHSCategoryId(
    2032018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_5C = new Section2GHSCategoryId(
    2032019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_1 =
    new Section2GHSCategoryId(
      2033000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_1A =
    new Section2GHSCategoryId(
      2033001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_1B =
    new Section2GHSCategoryId(
      2033002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_1C =
    new Section2GHSCategoryId(
      2033003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_2 =
    new Section2GHSCategoryId(
      2033004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_2A =
    new Section2GHSCategoryId(
      2033005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_2B =
    new Section2GHSCategoryId(
      2033006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_2C =
    new Section2GHSCategoryId(
      2033007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_3 =
    new Section2GHSCategoryId(
      2033008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_3A =
    new Section2GHSCategoryId(
      2033009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_3B =
    new Section2GHSCategoryId(
      2033010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_3C =
    new Section2GHSCategoryId(
      2033011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_4 =
    new Section2GHSCategoryId(
      2033012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_4A =
    new Section2GHSCategoryId(
      2033013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_4B =
    new Section2GHSCategoryId(
      2033014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_4C =
    new Section2GHSCategoryId(
      2033015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_5 =
    new Section2GHSCategoryId(
      2033016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_5A =
    new Section2GHSCategoryId(
      2033017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_5B =
    new Section2GHSCategoryId(
      2033018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_5C =
    new Section2GHSCategoryId(
      2033019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_1 =
    new Section2GHSCategoryId(
      2034000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_1A =
    new Section2GHSCategoryId(
      2034001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_1B =
    new Section2GHSCategoryId(
      2034002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_1C =
    new Section2GHSCategoryId(
      2034003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_2 =
    new Section2GHSCategoryId(
      2034004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_2A =
    new Section2GHSCategoryId(
      2034005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_2B =
    new Section2GHSCategoryId(
      2034006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_2C =
    new Section2GHSCategoryId(
      2034007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_3 =
    new Section2GHSCategoryId(
      2034008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_3A =
    new Section2GHSCategoryId(
      2034009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_3B =
    new Section2GHSCategoryId(
      2034010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_3C =
    new Section2GHSCategoryId(
      2034011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_4 =
    new Section2GHSCategoryId(
      2034012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_4A =
    new Section2GHSCategoryId(
      2034013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_4B =
    new Section2GHSCategoryId(
      2034014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_4C =
    new Section2GHSCategoryId(
      2034015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_5 =
    new Section2GHSCategoryId(
      2034016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_5A =
    new Section2GHSCategoryId(
      2034017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_5B =
    new Section2GHSCategoryId(
      2034018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_5C =
    new Section2GHSCategoryId(
      2034019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_1 =
    new Section2GHSCategoryId(
      2035000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_1A =
    new Section2GHSCategoryId(
      2035001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_1B =
    new Section2GHSCategoryId(
      2035002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_1C =
    new Section2GHSCategoryId(
      2035003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_2 =
    new Section2GHSCategoryId(
      2035004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_2A =
    new Section2GHSCategoryId(
      2035005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_2B =
    new Section2GHSCategoryId(
      2035006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_2C =
    new Section2GHSCategoryId(
      2035007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_3 =
    new Section2GHSCategoryId(
      2035008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_3A =
    new Section2GHSCategoryId(
      2035009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_3B =
    new Section2GHSCategoryId(
      2035010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_3C =
    new Section2GHSCategoryId(
      2035011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_4 =
    new Section2GHSCategoryId(
      2035012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_4A =
    new Section2GHSCategoryId(
      2035013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_4B =
    new Section2GHSCategoryId(
      2035014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_4C =
    new Section2GHSCategoryId(
      2035015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_5 =
    new Section2GHSCategoryId(
      2035016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_5A =
    new Section2GHSCategoryId(
      2035017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_5B =
    new Section2GHSCategoryId(
      2035018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_5C =
    new Section2GHSCategoryId(
      2035019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string,
    readonly itemId: number
  ) {
    Section2GHSCategoryId._values.push(this);
  }

  static fromId(value: number): Section2GHSCategoryId {
    const res = Section2GHSCategoryId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return Section2GHSCategoryId.DEFAULT;
    }
  }

  static values(): Section2GHSCategoryId[] {
    return Section2GHSCategoryId._values;
  }

  static valuesFromItemId(id: number): Section2GHSCategoryId[] {
    return Section2GHSCategoryId._values.filter((x) => x.itemId == id);
  }
}
