export class PlanId {
  private static readonly _values: PlanId[] = [];
  static readonly FREE = new PlanId(0, 'フリープラン', '無料', 10, '10件');
  static readonly PAID_100 = new PlanId(100, '100件', '15,000円', 100, '100件');
  static readonly PAID_250 = new PlanId(250, '250件', '-円', 250, '250件');
  static readonly PAID_300 = new PlanId(300, '300件', '20,000円', 300, '300件');
  static readonly PAID_500 = new PlanId(500, '500件', '25,000円', 500, '500件');
  static readonly PAID_1000 = new PlanId(
    1000,
    '1,000件',
    '40,000円',
    1000,
    '1,000件'
  );
  static readonly PAID_2500 = new PlanId(
    2500,
    '2,500件',
    '60,000円',
    2500,
    '2,500件'
  );
  static readonly PAID_4000 = new PlanId(
    4000,
    '4,000件',
    '40,000円',
    4000,
    '4,000件'
  );
  static readonly PAID_5000 = new PlanId(
    5000,
    '5,000件',
    '75,000円',
    5000,
    '5,000件'
  );
  static readonly PAID_7500 = new PlanId(
    7500,
    '7,500件',
    '90,000円',
    7500,
    '7,500件'
  );
  static readonly PAID_10000 = new PlanId(
    10000,
    '10,000件',
    '100,000円',
    10000,
    '10,000件'
  );
  static readonly PAID_15000 = new PlanId(
    15000,
    '15,000件',
    '-円',
    15000,
    '15,000件'
  );
  static readonly PAID_20000 = new PlanId(
    20000,
    '20,000件',
    '-円',
    20000,
    '20,000件'
  );
  static readonly PAID_30000 = new PlanId(
    30000,
    '30,000件',
    '-円',
    30000,
    '30,000件'
  );
  static readonly PAID_40000 = new PlanId(
    40000,
    '40,000件',
    '-円',
    40000,
    '40,000件'
  );
  static readonly PAID_50000 = new PlanId(
    50000,
    '50,000件',
    '-円',
    50000,
    '50,000件'
  );
  static readonly PAID_UNLIMITED = new PlanId(
    1000000000,
    '上限なし',
    'お問い合わせください',
    Infinity,
    '上限なし'
  );

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly price: string,
    readonly maxUpload: number,
    readonly maxUploadText: string
  ) {
    PlanId._values.push(this);
  }

  static fromPlanId(value: number): PlanId {
    const res = PlanId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return PlanId.FREE;
    }
  }

  static values(): PlanId[] {
    return PlanId._values;
  }
}
