export class CompositionId {
  private static readonly _values: CompositionId[] = [];

  static readonly DEFAULT = new CompositionId(
    0,
    'なし ; None',
    'なし',
    ' None'
  );
  static readonly SUBSTANCE = new CompositionId(
    100,
    '単一製品・化学物質 ; Substance',
    '単一製品・化学物質',
    'Substance'
  );
  static readonly MIXTURES = new CompositionId(
    101,
    '混合物 ; Mixtures (preparation)',
    '混合物',
    'Mixtures (preparation)'
  );
  static readonly UVCB = new CompositionId(
    102,
    'UVCB物質 ; UVCB',
    'UVCB物質',
    'UVCB'
  );
  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string
  ) {
    CompositionId._values.push(this);
  }

  static fromId(value: number): CompositionId {
    const res = CompositionId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return CompositionId.DEFAULT;
    }
  }

  static values(): CompositionId[] {
    return CompositionId._values;
  }
}
