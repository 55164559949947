export class LawId {
  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string,
    readonly textCheckListRequest: string
  ) {
    LawId._values.push(this);
  }

  private static readonly _values: LawId[] = [];

  static readonly DEFAULT = new LawId(0, '', '', '', '');
  static readonly LAW_ENCS = new LawId(
    100000000,
    '化学物質の審査及び製造等の規制に関する法律 ; 化審法 ; Chemical Substances Control Law ; CSCL | ENCS',
    '化学物質の審査及び製造等の規制に関する法律 ; 化審法',
    'Chemical Substances Control Law ; CSCL | ENCS',
    '化学物質の審査及び製造等の規制に関する法律 ; 化審法'
  );
  static readonly LAW_PRTR = new LawId(
    100010000,
    '化学物質排出把握管理促進法（PRTR） ; 化管法 ; Act on Confirmation, etc. of Release Amounts of Specific Chemical Substances in the Environment and Promotion of Improvements to the Management Thereof ; PRTR',
    '化学物質排出把握管理促進法（PRTR） ; 化管法',
    'Act on Confirmation, etc. of Release Amounts of Specific Chemical Substances in the Environment and Promotion of Improvements to the Management Thereof ; PRTR',
    '化学物質排出把握管理促進法（PRTR） ; 化管法'
  );
  static readonly LAW_ISHL = new LawId(
    100020000,
    '労働安全衛生法 ; 安衛法 | 労安法 ; Industrial Safety and Health Act ; ISHA | ISHL',
    '労働安全衛生法 ; 安衛法 | 労安法',
    'Industrial Safety and Health Act ; ISHA | ISHL',
    '労働安全衛生法 ; 安衛法 | 労安法'
  );
  static readonly LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT =
    new LawId(
      100030000,
      '毒物及び劇物取締法 ; 毒劇法 ; Poisonous and Deleterious Substances Control Act ; -',
      '毒物及び劇物取締法 ; 毒劇法',
      'Poisonous and Deleterious Substances Control Act ; -',
      '毒物及び劇物取締法 ; 毒劇法'
    );
  static readonly LAW_FIRE_SERVICE_ACT = new LawId(
    100040000,
    '消防法 ; - ; Fire Service Act ; -',
    '消防法',
    'Fire Service Act',
    '消防法'
  );
  static readonly LAW_FOREIGN_EXCHANGE_AND_FOREIGN_TRADE_ACT = new LawId(
    100050000,
    '外国為替及び外国貿易法 ; 外為法 ; Foreign Exchange and Foreign Trade Act ; -',
    '外国為替及び外国貿易法 ; 外為法',
    'Foreign Exchange and Foreign Trade Act',
    '外国為替及び外国貿易法 ; 外為法'
  );
  static readonly LAW_ACT_ON_THE_PROTECTION_OF_THE_OZONE_LAYER = new LawId(
    100060000,
    '特定物質等の規制等によるオゾン層の保護に関する法律 ; オゾン層保護法 ; Act on the Protection of the Ozone Layer through the Control and Other Measures on Specified Substances and Other Substances ; -',
    '特定物質等の規制等によるオゾン層の保護に関する法律 ; オゾン層保護法',
    'Act on the Protection of the Ozone Layer through the Control and Other Measures on Specified Substances and Other Substances',
    '特定物質等の規制等によるオゾン層の保護に関する法律 ; オゾン層保護法'
  );
  static readonly LAW_AIR_POLLUTION_CONTROL_ACT = new LawId(
    100070000,
    '大気汚染防止法 ; - ; Air Pollution Control Act ; -',
    '大気汚染防止法',
    'Air Pollution Control Act',
    '大気汚染防止法'
  );
  static readonly LAW_WATER_POLLUTION_PREVENTION_ACT = new LawId(
    100080000,
    '水質汚濁防止法 ; - ; Water Pollution Prevention Act ; -',
    '水質汚濁防止法',
    'Water Pollution Prevention Act',
    '水質汚濁防止法'
  );
  static readonly LAW_SOIL_CONTAMINATION_COUNTERMEASURES_ACT = new LawId(
    100090000,
    '土壌汚染対策法 ; - ; Soil Contamination Countermeasures Act ; -',
    '土壌汚染対策法',
    'Soil Contamination Countermeasures Act',
    '土壌汚染対策法'
  );
  static readonly LAW_FOOD_SANITATION_ACT = new LawId(
    100100000,
    '食品衛生法 ; - ; Food Sanitation Act ; -',
    '食品衛生法',
    'Food Sanitation Act',
    '食品衛生法'
  );
  static readonly LAW_ACT_ON_PREVENTION_OF_MARINE_POLLUTION = new LawId(
    100110000,
    '海洋汚染等及び海上災害の防止に関する法律 ; 海洋汚染防止法 ; Act on Prevention of Marine Pollution and Maritime Disaster ; -',
    '海洋汚染等及び海上災害の防止に関する法律 ; 海洋汚染防止法',
    'Act on Prevention of Marine Pollution and Maritime Disaster',
    '海洋汚染等及び海上災害の防止に関する法律 ; 海洋汚染防止法'
  );
  static readonly LAW_SHIP_SAFETY_LAW = new LawId(
    100120000,
    '船舶安全法 ; - ; Ship Safety Law ; -',
    '船舶安全法',
    'Ship Safety Law',
    '船舶安全法'
  );
  static readonly LAW_CIVIL_AERONAUTICS_ACT = new LawId(
    100130000,
    '航空法 ; - ; Civil Aeronautics Act ; -',
    '航空法',
    'Civil Aeronautics Act',
    '航空法'
  );
  static readonly LAW_PORT_REGULATIONS_ACT = new LawId(
    100140000,
    '港則法  ; - ; Port Regulations Act ; -',
    '港則法',
    'Port Regulations Act',
    '港則法'
  );
  static readonly LAW_WASTE_MANAGEMENT_AND_PUBLIC_CLEANSING_ACT = new LawId(
    100150000,
    '廃棄物の処理及び清掃に関する法律 ; 廃棄物処理法 | 廃掃法 ; Waste Management and Public Cleansing Act ; -',
    '廃棄物の処理及び清掃に関する法律 ; 廃棄物処理法 | 廃掃法',
    'Waste Management and Public Cleansing Act',
    '廃棄物の処理及び清掃に関する法律 ; 廃棄物処理法 | 廃掃法'
  );
  static readonly LAW_ACT_ON_PROHIBITION_OF_CHEMICAL_WEAPONS = new LawId(
    100160000,
    '化学兵器の禁止及び特定物質の規制等に関する法律 ; 化学兵器禁止法 ; Act on Prohibition of Chemical Weapons and Control, etc. of Specific Chemicals ; -',
    '化学兵器の禁止及び特定物質の規制等に関する法律 ; 化学兵器禁止法',
    'Act on Prohibition of Chemical Weapons and Control, etc. of Specific Chemicals',
    '化学兵器の禁止及び特定物質の規制等に関する法律 ; 化学兵器禁止法'
  );
  static readonly LAW_ACT_ON_THE_CONTROL_OF_HOUSEHOLD_PRODUCTS_CONTAINING_HARMFUL_SUBSTANCES =
    new LawId(
      100170000,
      '有害物質を含有する家庭用品の規制に関する法律 ; 家庭用品規制法 ; Act on the Control of Household Products Containing Harmful Substances ; -',
      '有害物質を含有する家庭用品の規制に関する法律 ; 家庭用品規制法',
      'Act on the Control of Household Products Containing Harmful Substances',
      '有害物質を含有する家庭用品の規制に関する法律 ; 家庭用品規制法'
    );
  static readonly LAW_HIGH_PRESSURE_GAS_SAFETY_ACT = new LawId(
    100180000,
    '高圧ガス保安法 ; - ; High Pressure Gas Safety Act ; -',
    '高圧ガス保安法',
    'High Pressure Gas Safety Act',
    '高圧ガス保安法'
  );
  static readonly LAW_THE_PNEUMOCONIOSIS_LAW = new LawId(
    100190000,
    'じん肺法 ; - ; The Pneumoconiosis Law ; -',
    'じん肺法',
    'The Pneumoconiosis Law',
    'じん肺法'
  );
  static readonly LAW_ETHANOL_BUSINESS_ACT = new LawId(
    100200000,
    'アルコール事業法 ; - ; Ethanol Business Act ; -',
    'アルコール事業法',
    'Ethanol Business Act',
    'アルコール事業法'
  );
  static readonly LAW_LIQUOR_TAX_LAW = new LawId(
    100210000,
    '酒税法 ; - ; Liquor Tax Law ; -',
    '酒税法',
    'Liquor Tax Law',
    '酒税法'
  );
  static readonly LAW_ACT_ON_PROMOTION_OF_GLOBAL_WARMING_COUNTERMEASURES =
    new LawId(
      100220000,
      '地球温暖化対策の推進に関わる法律 ; - ; Act on Promotion of Global Warming Countermeasures ; -',
      '地球温暖化対策の推進に関わる法律',
      'Act on Promotion of Global Warming Countermeasures',
      '地球温暖化対策の推進に関わる法律'
    );
  static readonly LAW_WATER_SUPPLY_ACT = new LawId(
    100230000,
    '水道法 ; - ; Water Supply Act ; -',
    '水道法',
    'Water Supply Act',
    '水道法'
  );
  static readonly LAW_SEWERAGE_ACT = new LawId(
    100240000,
    '下水道法 ; - ; Sewerage Act ; -',
    '下水道法',
    'Sewerage Act',
    '下水道法'
  );
  static readonly LAW_LABOR_STANDARDS_ACT = new LawId(
    100250000,
    '労働基準法 ; - ; Labor Standards Act ; -',
    '労働基準法',
    'Labor Standards Act',
    '労働基準法'
  );
  static readonly LAW_ROAD_ACT = new LawId(
    100260000,
    '道路法 ; - ; Road Act ; -',
    '道路法',
    'Road Act',
    '道路法'
  );
  static readonly LAW_ACT_ON_CONTROL_OF_EXPORT_IMPORT_AND_OTHERS_OF_SPECIFIED_HAZARDOUS_WASTES_AND_OTHER_WASTES =
    new LawId(
      100270000,
      '特定有害廃棄物輸出入規制法（バーゼル法） ; - ; Act on Control of Export, Import and Others of Specified Hazardous Wastes and Other Wastes ; -',
      '特定有害廃棄物輸出入規制法（バーゼル法）',
      'Act on Control of Export, Import and Others of Specified Hazardous Wastes and Other Wastes',
      '特定有害廃棄物輸出入規制法（バーゼル法）'
    );
  static readonly LAW_ORDER_FOR_ENFORCEMENT_OF_THE_AGRICULTURAL_CHEMICALS_REGULATION_ACT =
    new LawId(
      100280000,
      '農薬取締法 ; - ; Order for Enforcement of the Agricultural Chemicals Regulation Act ; -',
      '農薬取締法',
      'Order for Enforcement of the Agricultural Chemicals Regulation Act',
      '農薬取締法'
    );
  static readonly LAW_OFFENSIVE_ODOR_CONTROL_ACT = new LawId(
    100290000,
    '悪臭防止法 ; - ; Offensive Odor Control Act ; -',
    '悪臭防止法',
    'Offensive Odor Control Act',
    '悪臭防止法'
  );
  static readonly LAW_PMD_ACT = new LawId(
    100300000,
    '医薬品医療機器等法 ; 薬機法 ; Pharmaceutical and Medical Device Act ; PMD Act',
    '医薬品医療機器等法 ; 薬機法',
    'Pharmaceutical and Medical Device Act ; PMD Act',
    '医薬品医療機器等法 ; 薬機法'
  );
  static readonly LAW_EXPLOSIVES_CONTROL_ACT = new LawId(
    100310000,
    '火薬類取締法 ; - ; Explosives Control Act ; -',
    '火薬類取締法',
    'Explosives Control Act',
    '火薬類取締法'
  );
  static readonly LAW_NARCOTICS_AND_PSYCHOTROPICS_CONTROL_ACT = new LawId(
    100320000,
    '麻薬及び向精神薬取締法 ; - ; Narcotics and Psychotropics Control Act ; -',
    '麻薬及び向精神薬取締法',
    'Narcotics and Psychotropics Control Act',
    '麻薬及び向精神薬取締法'
  );
  static readonly LAW_STIMULANTS_CONTROL_ACT = new LawId(
    100330000,
    '覚せい剤取締法 ; - ; Stimulants Control Act ; -',
    '覚せい剤取締法',
    'Stimulants Control Act',
    '覚せい剤取締法'
  );
  static readonly LAW_JAS = new LawId(
    100340000,
    '日本農林規格等に関する法律 ; JAS法 ; Japanese Agricultural Standard ; JAS',
    '日本農林規格等に関する法律 ; JAS法',
    'Japanese Agricultural Standard ; JAS',
    '日本農林規格等に関する法律 ; JAS法'
  );
  static readonly LAW_MEASUREMENT_ACT = new LawId(
    100350000,
    '計量法 ; - ; Measurement Act ; -',
    '計量法',
    'Measurement Act',
    '計量法'
  );
  static readonly LAW_WORKING_ENVIRONMENT_MEASUREMENT_ACT = new LawId(
    100360000,
    '作業環境測定法 ; - ; Working Environment Measurement Act ; -',
    '作業環境測定法',
    'Working Environment Measurement Act',
    '作業環境測定法'
  );
  static readonly LAW_CRUSHED_STONE_ACT = new LawId(
    100370000,
    '砕石法 ; - ; Crushed Stone Act ; -',
    '砕石法',
    'Crushed Stone Act',
    '砕石法'
  );
  static readonly LAW_ACT_ON_SAFETY_ASSURANCE = new LawId(
    100380000,
    '飼料の安全性の確保及び品質の改善に関する法律 ; 飼料安全法 ; Act on Safety Assurance and Quality Improvement of Feeds ; -',
    '飼料の安全性の確保及び品質の改善に関する法律 ; 飼料安全法',
    'Act on Safety Assurance and Quality Improvement of Feeds',
    '飼料の安全性の確保及び品質の改善に関する法律 ; 飼料安全法'
  );
  static readonly LAW_FERTILIZER_CONTROL_ACT = new LawId(
    100390000,
    '肥料の品質の確保等に関する法律 ; - ; Fertilizer Control Act ; -',
    '肥料の品質の確保等に関する法律',
    'Fertilizer Control Act',
    '肥料の品質の確保等に関する法律'
  );
  static readonly LAW_MARINERS_LAW = new LawId(
    100400000,
    '船員法 ; - ; Mariners Law ; -',
    '船員法',
    'Mariners Law',
    '船員法'
  );
  static readonly LAW_JSQI = new LawId(
    100410000,
    '医薬部外品原料規格 ; - ; Japanese Standards of Quasi-drug Ingredients ; JSQI',
    '医薬部外品原料規格',
    'Japanese Standards of Quasi-drug Ingredients ; JSQI',
    '医薬部外品原料規格'
  );
  static readonly LAW_JSCI = new LawId(
    100420000,
    '化粧品原料基準 ; - ; The Japanese Standards of Cosmetic Ingredients ; JSCI',
    '化粧品原料基準',
    'The Japanese Standards of Cosmetic Ingredients ; JSCI',
    '化粧品原料基準'
  );
  static readonly LAW_THE_PHARMACEUTICAL_AFFAIRS_LAW = new LawId(
    100430000,
    '薬事法 ; - ; The Pharmaceutical Affairs Law ; -',
    '薬事法',
    'The Pharmaceutical Affairs Law',
    '薬事法'
  );
  static readonly LAW_BUILDING_STANDARDS_ACT = new LawId(
    100440000,
    '建築基準法 ; - ; Building Standards Act ; -',
    '建築基準法',
    'Building Standards Act',
    '建築基準法'
  );
  static readonly LAW_SALT_INDUSTRY_ACT = new LawId(
    100450000,
    '塩事業法 ; - ; Salt Industry Act ; -',
    '塩事業法',
    'Salt Industry Act',
    '塩事業法'
  );
  static readonly LAW_ROAD_TRUCKING_VEHICLE_ACT = new LawId(
    100460000,
    '道路運送車両法 ; - ; Road Trucking Vehicle Act ; -',
    '道路運送車両法',
    'Road Trucking Vehicle Act',
    '道路運送車両法'
  );
  static readonly LAW_THE_CONTAINERS_AND_PACKAGING_RECYCLING_LAW = new LawId(
    100470000,
    '包装容器リサイクル法 ; - ; The Containers and Packaging Recycling Law ; -',
    '包装容器リサイクル法',
    'The Containers and Packaging Recycling Law',
    '包装容器リサイクル法'
  );
  static readonly LAW_EXPLOSIVES_CONTROL_LAW = new LawId(
    100480000,
    '火薬取締法 ; - ; Explosives Control Law ; -',
    '火薬取締法',
    'Explosives Control Law',
    '火薬取締法'
  );
  static readonly LAW_IMDG = new LawId(
    100490000,
    '国際海上危険物規定 ; - ; International Maritime Dangerous Goods Code ; IMDG',
    '国際海上危険物規定',
    'International Maritime Dangerous Goods Code',
    '国際海上危険物規定'
  );
  static readonly LAW_ICAO = new LawId(
    100500000,
    '国際民間航空機関 ; - ; International Civil Aviation Organization ; ICAO',
    '国際民間航空機関',
    'International Civil Aviation Organization',
    '国際民間航空機関'
  );
  static readonly LAW_FIRE_PREVENTION_PROPERTY = new LawId(
    100510000,
    '火災予防条例 ; - ; Fire Prevention Property ; -',
    '火災予防条例',
    'Fire Prevention Property',
    '火災予防条例'
  );
  static readonly LAW_MARPOL = new LawId(
    100520000,
    'マルポール条約 ; - ; International Convention for the Prevention of Pollution from Ships, 1973, as modified by the Protocol of 1978 relating thereto ; MARPOL',
    'マルポール条約',
    '',
    'マルポール条約'
  );
  static readonly LAW_ACT_CONCERNING_THE_RECOVERY_AND_DESTRUCTION_OF_FLUOROCARBONS =
    new LawId(
      100530000,
      '特定製品に係るフロン類の回収及び破壊の実施の確保等に関する法律 ; フロン回収破壊法 ; Act Concerning the Recovery and Destruction of Fluorocarbons ; -',
      '特定製品に係るフロン類の回収及び破壊の実施の確保等に関する法律',
      'Act Concerning the Recovery and Destruction of Fluorocarbons',
      '特定製品に係るフロン類の回収及び破壊の実施の確保等に関する法律'
    );
  static readonly LAW_CWC = new LawId(
    100540000,
    '国際化学兵器禁止条約 ; - ; Chemical Weapons Convention ; CWC',
    '国際化学兵器禁止条約',
    'Chemical Weapons Convention',
    '国際化学兵器禁止条約'
  );
  static readonly LAW_ACT_TO_PREVENT_SOIL_CONTAMINATION_ON_AGRICULTURAL_LAND =
    new LawId(
      100550000,
      '農用地の土壌の汚染防止等に関する法律 ; 農用地汚染防止法 ; Act to Prevent Soil Contamination on Agricultural Land ; -',
      '農用地の土壌の汚染防止等に関する法律',
      'Act to Prevent Soil Contamination on Agricultural Land',
      '農用地の土壌の汚染防止等に関する法律'
    );
  static readonly LAW_POPS = new LawId(
    100560000,
    '残留性有機汚染物質に関するストックホルム条約 ; POPs条約 ; Stockholm Convention on Persistent Organic Pollutants ; POPs',
    '残留性有機汚染物質に関するストックホルム条約',
    'Stockholm Convention on Persistent Organic Pollutants',
    '残留性有機汚染物質に関するストックホルム条約'
  );
  static readonly LAW_EXPORT_TRADE_CONTROL_ORDER = new LawId(
    100570000,
    '輸出貿易管理令 ; - ; Export Trade Control Order ; -',
    '輸出貿易管理令',
    'Export Trade Control Order',
    '輸出貿易管理令'
  );
  static readonly LAW_OSHA = new LawId(
    100580000,
    '改正産業安全保健法 ; 改正OSHA ; - ; -',
    '改正産業安全保健法',
    'OSHA',
    '改正産業安全保健法'
  );
  static readonly LAW_ACT_CONCERNING_THE_SECURING_OF_SAFETY_AND_THE_OPTIMIZATION_OF_TRANSACTION_OF_LIQUEFIED_PETROLEUM_GAS =
    new LawId(
      100590000,
      '液化石油ガスの保安の確保及び取引の適正化に関する法律 ; - ; Act Concerning the Securing of Safety and the Optimization of Transaction of Liquefied Petroleum Gas ; -',
      '液化石油ガスの保安の確保及び取引の適正化に関する法律',
      'Act Concerning the Securing of Safety and the Optimization of Transaction of Liquefied Petroleum Gas',
      '液化石油ガスの保安の確保及び取引の適正化に関する法律'
    );
  static readonly LAW_CUSTOMS_TARIFF_ACT = new LawId(
    100600000,
    '関税定率法 ; - ; Customs Tariff Act ; -',
    '関税定率法',
    'Customs Tariff Act',
    '関税定率法'
  );
  static readonly LAW_FOOD_LABELING_ACT = new LawId(
    100610000,
    '食品表示法 ; - ; Food Labeling Act ; -',
    '食品表示法',
    'Food Labeling Act',
    '食品表示法'
  );
  static readonly LAW_OPIUM_CONTROL_ACT = new LawId(
    100620000,
    'あへん法 ; - ; Opium Control Act ; -',
    'あへん法',
    'Opium Control Act',
    'あへん法'
  );
  static readonly LAW_ACT_CONCERNING_SPECIAL_PROVISIONS_FOR_THE_NARCOTICS =
    new LawId(
      100630000,
      '国際的な協力の下に規制薬物に係る不正行為を助長する行為等の防止を図るための麻薬及び向精神薬取締法等の特例等に関する法律 ; 麻薬特例法 ; - ; -',
      '国際的な協力の下に規制薬物に係る不正行為を助長する行為等の防止を図るための麻薬及び向精神薬取締法等の特例等に関する法律',
      'Act Concerning Special Provisions for the Narcotics and Psychotropics Control Act, etc. and Other Matters for the Prevention of Activities Encouraging Illicit Conducts and Other Activities Involving Controlled Substances through International Cooperation',
      '国際的な協力の下に規制薬物に係る不正行為を助長する行為等の防止を図るための麻薬及び向精神薬取締法等の特例等に関する法律'
    );
  static readonly LAW_CANNABIS_CONTROL_ACT = new LawId(
    100640000,
    '大麻取締法 ; - ; Cannabis Control Act ; -',
    '大麻取締法',
    'Cannabis Control Act',
    '大麻取締法'
  );
  static readonly LAW_HOUSEHOLD_GOODS_QUALITY_LABELING_ACT = new LawId(
    100650000,
    '家庭用品品質表示法 ; - ; Household Goods Quality Labeling Act ; -',
    '家庭用品品質表示法',
    'Household Goods Quality Labeling Act',
    '家庭用品品質表示法'
  );
  static readonly LAW_HOME_APPLIANCE_RECYCLING_LAW = new LawId(
    100660000,
    '特定家庭用機器再商品化法 ; 家電リサイクル法 ; Act on Recycling of Specified Kinds of Home Appliance ; Home Appliance Recycling Law',
    '特定家庭用機器再商品化法',
    'Home Appliance Recycling Law',
    '特定家庭用機器再商品化法'
  );
  static readonly LAW_MONTREAL_PROTOCOL_ON_SUBSTANCES_THAT_DEPLETE_THE_OZONE_LAYER =
    new LawId(
      100670000,
      'オゾン層を破壊する物質に関するモントリオール議定書 ; モントリオール議定書 ; Montreal Protocol on Substances that Deplete the Ozone Layer ; -',
      'オゾン層を破壊する物質に関するモントリオール議定書',
      'Montreal Protocol on Substances that Deplete the Ozone Layer',
      'オゾン層を破壊する物質に関するモントリオール議定書'
    );
  static readonly LAW_PIC = new LawId(
    100680000,
    'ロッテルダム条約 ; - ; Prior Informed Consent ; PIC',
    'ロッテルダム条約',
    'PIC',
    'ロッテルダム条約'
  );
  static readonly LAW_ORDINANCE_ON_PREVENTION_OF_HAZARDS_DUE_TO_DUST_MINISTRY_OF_LABOUR_ORDINANCE =
    new LawId(
      100690000,
      '粉塵障害防止規則 ; - ; Ordinance on Prevention of Hazards Due to Dust Ministry of Labour Ordinance ; -',
      '粉塵障害防止規則',
      '',
      '粉塵障害防止規則'
    );
  static readonly LAW_ACT_ON_TEMPORARY_MEASURES_CONCERNING_CUSTOMS = new LawId(
    100700000,
    '関税暫定措置法 ; - ; Act on Temporary Measures concerning Customs ; -',
    '関税暫定措置法',
    'Act on Temporary Measures concerning Customs',
    '関税暫定措置法'
  );

  static fromId(value: number): LawId {
    const res = LawId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return LawId.DEFAULT;
    }
  }

  static values(): LawId[] {
    return LawId._values;
  }
}
