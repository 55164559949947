import * as qs from 'qs';

import { apiUrl } from '@/env';
import {
  CompanyDepartmentCreateRequestBody,
  CompanyDepartmentCreateResponse,
  CompanyDepartmentUpdateRequestBody,
  CompanyDepartmentUpdateResponse,
  CompanySdsCountResponse,
  CompanySdsDataResponse,
  CompanySdsIndexResponse,
  CompanySdsShowResponse,
  CompanyShowResponse,
  CompanyUpdateRequestBody,
  CompanyUpdateRequestParams,
  CompanyUserCreateRequestBody,
  CompanyUserCreateResponse,
  CompanyWhitelistIpCreateRequestBody,
  CompanyWhitelistIpCreateResponse,
  CreateCompanySdsRequestBody,
  CreateCompanySdsRequestUnit,
  DeleteCompanySdsesRequestBody,
  UpdateCompanySdsRequestBody,
  CompanyTagCreateRequestBody,
  CompanyTagCreateResponse,
  CompanyTagUpdateRequestBody,
  UpdateCompanySdsesRequestBody,
  UpdateCompanyUserRequestBody,
  UpdateReconfirmationSdsRequestBody,
  GetCompanySdsesRequestQuery,
  GetCompanySdsesAsExcelRequestQuery,
  GetCompanySdsesCountRequestQuery,
} from '@/interfaces/api/general/company';
import { DepartmentShowResponse } from '@/interfaces/api/general/department';
import {
  CreateDynamicListRequestBody,
  GetDynamicList,
  GetDynamicListResultsRequestBody,
  GetDynamicLists,
  RequestDynamicListRequestBody,
} from '@/interfaces/api/general/dynamicList';
import { DynamicListResultRequestBody } from '@/interfaces/api/general/dynamicListResult';
import {
  InquiryRequestBody,
  QuotationRequestBody,
} from '@/interfaces/api/general/info';
import { SignUpRequestBody } from '@/interfaces/api/general/signIn';
import {
  CompanyUsersShowResponse,
  UserShowResponse,
  UserUpdateRequestBody,
  UserUpdateResponse,
} from '@/interfaces/api/general/user';
import axios, { CancelTokenSource } from 'axios';

let cancelTokenSource: CancelTokenSource | null = null;

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const generalApi = {
  async inquiry(data: InquiryRequestBody) {
    return axios.post(`${apiUrl}/api/v1/info/inquiry`, data);
  },
  async inquiryEn(data: InquiryRequestBody) {
    return axios.post(`${apiUrl}/api/v1/info/en/inquiry`, data);
  },
  async quotation(data: QuotationRequestBody) {
    return axios.post(`${apiUrl}/api/v1/info/quotation`, data);
  },
  async signUp(data: SignUpRequestBody) {
    return axios.post(`${apiUrl}/api/v1/signup`, data);
  },
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getCompany(token: string, companyId: string) {
    return axios.get<CompanyShowResponse>(
      `${apiUrl}/api/v1/companies/${companyId}`,
      authHeaders(token)
    );
  },
  async updateCompany(
    token: string,
    params: CompanyUpdateRequestParams,
    payload: CompanyUpdateRequestBody
  ) {
    return axios.put(
      `${apiUrl}/api/v1/companies/${params.companyId}`,
      payload,
      authHeaders(token)
    );
  },
  async deleteCompany(token: string, companyId: string) {
    return axios.delete(
      `${apiUrl}/api/v1/companies/${companyId}`,
      authHeaders(token)
    );
  },
  async getCompanyDepartments(token: string, companyId: string) {
    return axios.get<DepartmentShowResponse>(
      `${apiUrl}/api/v1/companies/${companyId}/departments`,
      authHeaders(token)
    );
  },
  async createCompanyDepartment(
    token: string,
    companyId: string,
    data: CompanyDepartmentCreateRequestBody
  ) {
    return axios.post<CompanyDepartmentCreateResponse>(
      `${apiUrl}/api/v1/companies/${companyId}/departments`,
      data,
      authHeaders(token)
    );
  },
  async updateCompanyDepartment(
    token: string,
    companyId: string,
    departmentId: string,
    data: CompanyDepartmentUpdateRequestBody
  ) {
    return axios.put<CompanyDepartmentUpdateResponse>(
      `${apiUrl}/api/v1/companies/${companyId}/departments/${departmentId}`,
      data,
      authHeaders(token)
    );
  },
  async deleteCompanyDepartment(
    token: string,
    companyId: string,
    departmentId: string
  ) {
    return axios.delete(
      `${apiUrl}/api/v1/companies/${companyId}/departments/${departmentId}`,
      authHeaders(token)
    );
  },

  async updateCompanyUser(
    token: string,
    companyId: string,
    userId: string,
    data: UpdateCompanyUserRequestBody
  ) {
    return axios.put(
      `${apiUrl}/api/v1/companies/${companyId}/users/${userId}`,
      data,
      authHeaders(token)
    );
  },

  async getMe(token: string) {
    return axios.get<UserShowResponse>(
      `${apiUrl}/api/v1/users/me`,
      authHeaders(token)
    );
  },
  async updateMe(token: string, data: UserUpdateRequestBody) {
    return axios.put<UserUpdateResponse>(
      `${apiUrl}/api/v1/users/me`,
      data,
      authHeaders(token)
    );
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async regenerateActivationMail(data: { email: string }) {
    return axios.post(`${apiUrl}/api/v1/activation/regeneration`, data);
  },
  async activateUser(data: { token: string }) {
    return axios.put(`${apiUrl}/api/v1/signup/activation`, data);
  },
  async getCompanyUser(token: string, companyId: string) {
    return axios.get<CompanyUsersShowResponse>(
      `${apiUrl}/api/v1/companies/${companyId}/users`,
      authHeaders(token)
    );
  },
  async getCompanyUserCsv(token: string, companyId: string) {
    return axios.get(
      `${apiUrl}/api/v1/companies/${companyId}/users/csv/download`,
      authHeaders(token)
    );
  },
  async postCompanyUserCsv(token: string, companyId: string, data: FormData) {
    return axios.post(
      `${apiUrl}/api/v1/companies/${companyId}/users/csv/upload`,
      data,
      authHeaders(token)
    );
  },

  async createCompanyUser(
    token: string,
    companyId: string,
    data: CompanyUserCreateRequestBody
  ) {
    return axios.post<CompanyUserCreateResponse>(
      `${apiUrl}/api/v1/companies/${companyId}/users`,
      data,
      authHeaders(token)
    );
  },

  async deleteCompanyUser(token: string, companyId: string, email: string) {
    return axios.delete(
      `${apiUrl}/api/v1/companies/${companyId}/users/${email}`,
      authHeaders(token)
    );
  },
  async getCompanySds(token: string, companyId: string, sdsId: string) {
    return axios.get<CompanySdsShowResponse>(
      `${apiUrl}/api/v1/companies/${companyId}/sdses/${sdsId}`,
      authHeaders(token)
    );
  },

  async getCompanyUploadUsers(token: string, companyId: string) {
    return axios.get<Array<UserShowResponse>>(
      `${apiUrl}/api/v1/companies/${companyId}/users`,
      authHeaders(token)
    );
  },

  async getCompanySdses(
    token: string,
    companyId: string,
    data: GetCompanySdsesRequestQuery
  ) {
    const { sortState, ...body } = data;
    const query = qs.stringify(
      {
        ...body,
        order_by_created_at: sortState?.created_at,
        order_by_revised_at: sortState?.revised_at,
        order_by_name: sortState?.name,
        order_by_status: sortState?.status,
      },
      {
        indices: false,
      }
    );

    return axios.get<CompanySdsIndexResponse>(
      `${apiUrl}/api/v1/companies/${companyId}/sdses?${query}`,
      authHeaders(token)
    );
  },

  async getCompanySdsesCount(
    token: string,
    companyId: string,
    data: GetCompanySdsesCountRequestQuery
  ) {
    const query = qs.stringify(data, { indices: false });

    return axios.get<CompanySdsCountResponse>(
      `${apiUrl}/api/v1/companies/${companyId}/sdses/count?${query}`,
      authHeaders(token)
    );
  },
  async getCompanySdsPdf(token: string, companyId: string, sdsUuid: string) {
    return axios.get<CompanySdsDataResponse>(
      `${apiUrl}/api/v1/companies/${companyId}/sds/${sdsUuid}/pdf`,
      authHeaders(token)
    );
  },

  async getCompanySdsesExport(
    token: string,
    companyId: string,
    data: GetCompanySdsesAsExcelRequestQuery
  ) {
    const query = qs.stringify(data, { indices: false });

    return axios.get(
      `${apiUrl}/api/v1/companies/${companyId}/sdses/export?${query}`,
      authHeaders(token)
    );
  },

  async getGcsSignedUrl(
    token: string,
    companyId: string,
    sdsList: Array<CreateCompanySdsRequestUnit>
  ) {
    return axios.post<Array<CreateCompanySdsRequestUnit>>(
      `${apiUrl}/api/v1/companies/${companyId}/get_signed_url`,
      sdsList,
      authHeaders(token)
    );
  },

  async createSdses(
    token: string,
    companyId: string,
    data: CreateCompanySdsRequestBody
  ) {
    return axios.post(
      `${apiUrl}/api/v1/companies/${companyId}/sdses`,
      data,
      authHeaders(token)
    );
  },

  async updateCompanySdses(
    token: string,
    companyId: string,
    data: UpdateCompanySdsesRequestBody
  ) {
    return axios.put(
      `${apiUrl}/api/v1/companies/${companyId}/sdses`,
      data,
      authHeaders(token)
    );
  },

  async deleteCompanySdses(
    token: string,
    companyId: string,
    data: DeleteCompanySdsesRequestBody
  ) {
    return axios.put(
      `${apiUrl}/api/v1/companies/${companyId}/sdses/delete`,
      data,
      authHeaders(token)
    );
  },

  async updateCompanySds(
    token: string,
    companyId: string,
    sdsId: string,
    formData: UpdateCompanySdsRequestBody
  ) {
    return axios.put(
      `${apiUrl}/api/v1/companies/${companyId}/sdses/${sdsId}`,
      formData,
      authHeaders(token)
    );
  },

  async updateReconfirmationSds(
    token: string,
    companyId: string,
    sdsId: string,
    formData: UpdateReconfirmationSdsRequestBody
  ) {
    return axios.put(
      `${apiUrl}/api/v1/companies/${companyId}/sdses/${sdsId}/reconfirmation_sds`,
      formData,
      authHeaders(token)
    );
  },

  async getCompanyWhitelistIp(token: string, companyId: string) {
    return axios.get(
      `${apiUrl}/api/v1/companies/${companyId}/whitelist_ips`,
      authHeaders(token)
    );
  },

  async createCompanyWhitelistIp(
    token: string,
    companyId: string,
    data: CompanyWhitelistIpCreateRequestBody
  ) {
    return axios.post<CompanyWhitelistIpCreateResponse>(
      `${apiUrl}/api/v1/companies/${companyId}/whitelist_ips`,
      data,
      authHeaders(token)
    );
  },

  async deleteCompanyWhitelistIp(
    token: string,
    companyId: string,
    ipAddress: string
  ) {
    return axios.delete(
      `${apiUrl}/api/v1/companies/${companyId}/whitelist_ips/${ipAddress}`,
      authHeaders(token)
    );
  },

  async getClientIp(token: string) {
    return axios.get(
      `${apiUrl}/api/v1/utils/get-client-ip`,
      authHeaders(token)
    );
  },
  async getDynamicList(token: string, dynamicListId: number) {
    return axios.get<GetDynamicList>(
      `${apiUrl}/api/v1/dynamic_lists/${dynamicListId}`,
      authHeaders(token)
    );
  },
  async getDynamicLists(
    token: string,
    page: number,
    limit: number,
    showAll: boolean
  ) {
    const queryParams: { page: number; limit: number; show_all: boolean } = {
      page: page,
      limit: limit,
      show_all: showAll,
    };

    const query = qs.stringify(queryParams, { indices: false });

    return axios.get<GetDynamicLists>(
      `${apiUrl}/api/v1/dynamic_lists?${query}`,
      authHeaders(token)
    );
  },
  async getDynamicListResults(
    token: string,
    dynamicListId: number,
    data: GetDynamicListResultsRequestBody
  ) {
    const { sortState, ...body } = data;
    const query = qs.stringify(
      {
        ...body,
        order_by_name: sortState?.name,
        order_by_revised_at: sortState?.revised_at,
        order_by_created_at: sortState?.created_at,
        order_by_updated_at: sortState?.updated_at,
      },
      { indices: false }
    );
    return axios.get(
      `${apiUrl}/api/v1/dynamic_list_results/${dynamicListId}?${query}`,
      authHeaders(token)
    );
  },
  async getExportDynamicListResults(token: string, dynamicListId: number) {
    return axios.get(
      `${apiUrl}/api/v1/dynamic_list_results/${dynamicListId}/export`,
      {
        ...authHeaders(token),
        responseType: 'blob',
      }
    );
  },
  async createDynamicList(token: string, data: CreateDynamicListRequestBody) {
    return axios.post(
      `${apiUrl}/api/v1/dynamic_lists`,
      data,
      authHeaders(token)
    );
  },
  async requestDynamicList(token: string, data: RequestDynamicListRequestBody) {
    return axios.post(
      `${apiUrl}/api/v1/dynamic_lists/request`,
      data,
      authHeaders(token)
    );
  },
  async resumeDynamicLists(
    token: string,
    data: { dynamic_list_ids: number[] }
  ) {
    return axios.put(
      `${apiUrl}/api/v1/dynamic_lists/resume`,
      data,
      authHeaders(token)
    );
  },
  async suspendDynamicLists(
    token: string,
    data: { dynamic_list_ids: number[] }
  ) {
    return axios.put(
      `${apiUrl}/api/v1/dynamic_lists/suspend`,
      data,
      authHeaders(token)
    );
  },

  async getExportCreateSimple(token: string, sdsId: string) {
    cancelTokenSource = axios.CancelToken.source();

    try {
      const response = await axios.get<ArrayBuffer>(
        `${apiUrl}/api/v1/create_simple/${sdsId}`,
        {
          ...authHeaders(token),
          responseType: 'arraybuffer',
          cancelToken: cancelTokenSource.token,
        }
      );

      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request was cancelled');
      }
      throw error;
    }
  },

  async getExportCreateSimpleV3(token: string, sdsId: string) {
    cancelTokenSource = axios.CancelToken.source();

    try {
      const response = await axios.get<ArrayBuffer>(
        `${apiUrl}/api/v1/create_simple/v3/${sdsId}`,
        {
          ...authHeaders(token),
          responseType: 'arraybuffer',
          cancelToken: cancelTokenSource.token,
        }
      );

      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request was cancelled');
      }
      throw error;
    }
  },

  cancelCreateSimpleExportRequest() {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('User cancelled the request');
    }
  },

  cancelCreateSimpleV3ExportRequest() {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('User cancelled the request');
    }
  },

  async createReanalysisSds(
    token: string,
    data: { sdsId: string; comment: string }
  ) {
    const sdsId = data.sdsId;
    const comment = data.comment;
    return axios.post(
      `${apiUrl}/api/v1/sdses/${sdsId}/reanalysis`,
      { comment },
      authHeaders(token)
    );
  },

  async putDynamicListResult(
    token: string,
    sdsId: string,
    dynamicListId: number,
    dynamicListResult: DynamicListResultRequestBody
  ) {
    return axios.put(
      `${apiUrl}/api/v1/dynamic_list_results/${dynamicListId}/${sdsId}`,
      dynamicListResult,
      authHeaders(token)
    );
  },

  async postDynamicListResult(
    token: string,
    sdsId: string,
    dynamicListId: number
  ) {
    return axios.post(
      `${apiUrl}/api/v1/dynamic_list_results/${dynamicListId}/${sdsId}`,
      {},
      authHeaders(token)
    );
  },

  async deleteDynamicListResult(
    token: string,
    sdsId: string,
    dynamicListId: number
  ) {
    return axios.delete(
      `${apiUrl}/api/v1/dynamic_list_results/${dynamicListId}/${sdsId}`,
      authHeaders(token)
    );
  },

  async createCompanyTag(
    token: string,
    companyId: string,
    data: CompanyTagCreateRequestBody
  ) {
    return axios.post<CompanyTagCreateResponse>(
      `${apiUrl}/api/v1/companies/${companyId}/tags`,
      data,
      authHeaders(token)
    );
    console.log(data, token, companyId);
  },

  async getCompanyTag(token: string, companyId: string) {
    return axios.get(
      `${apiUrl}/api/v1/companies/${companyId}/tags`,
      authHeaders(token)
    );
  },

  async deleteCompanyTag(token: string, id: number) {
    return axios.delete(
      `${apiUrl}/api/v1/companies/tags/${id}`,
      authHeaders(token)
    );
  },

  async updateCompanyTag(token: string, data: CompanyTagUpdateRequestBody) {
    return axios.put<CompanyTagUpdateRequestBody>(
      `${apiUrl}/api/v1/companies/tags/${data.id}`,
      data,
      authHeaders(token)
    );
  },
};
