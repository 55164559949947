export class TemparatureUnitId {
  private static readonly _values: TemparatureUnitId[] = [];

  static readonly CELSIUS = new TemparatureUnitId(100, '℃', '℃', '℃');
  static readonly FAHRENHEIT = new TemparatureUnitId(101, '℉', '℉', '℉');
  static readonly KELVIN = new TemparatureUnitId(102, 'K', 'K', 'K');

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string
  ) {
    TemparatureUnitId._values.push(this);
  }

  static fromId(value: number): TemparatureUnitId {
    const res = TemparatureUnitId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return TemparatureUnitId.CELSIUS;
    }
  }

  static values(): TemparatureUnitId[] {
    return TemparatureUnitId._values;
  }
}
