export class LanguageId {
  private static readonly _values: LanguageId[] = [];

  static readonly JAPANESE = new LanguageId(44, '日本語');
  static readonly ENGLISH = new LanguageId(16, '英語');
  static readonly OTHER = new LanguageId(999, 'その他');

  private constructor(readonly value: number, readonly text: string) {
    LanguageId._values.push(this);
  }

  static fromLanguageId(value: number): LanguageId {
    const res = LanguageId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return LanguageId.OTHER;
    }
  }

  static values(): LanguageId[] {
    return LanguageId._values;
  }
}
