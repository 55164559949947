import { computed } from 'vue';
import { store } from '@/store/index';
import { readUserProfile } from '@/store/main/getters';
import { RoleId } from '@/lib/enum';
import { Department } from '@/interfaces/model/general/department';

const PERMISSIONS = {
  ALL_PRIVILEGES: 'ALL_PRIVILEGES',
  ALLOWED_IP_ADDRESS_CREATE: 'ALLOWED_IP_ADDRESS_CREATE',
  ALLOWED_IP_ADDRESS_DELETE: 'ALLOWED_IP_ADDRESS_DELETE',
  COMPANY_EDIT: 'COMPANY_EDIT',
  COMPANY_DELETE: 'COMPANY_DELETE',
  SDS_UPLOAD: 'SDS_UPLOAD',
  SDS_READ: 'SDS_READ',
  SDS_EDIT_OWN_DEPARTMENT: 'SDS_EDIT_OWN_DEPARTMENT',
  SDS_EDIT_OTHER_DEPARTMENT: 'SDS_EDIT_OTHER_DEPARTMENT',
  SDS_DELETE: 'SDS_DELETE',
  SDS_EXPORT: 'SDS_EXPORT',
  SDS_REQUEST_OWN_DEPARTMENT: 'SDS_REQUEST_OWN_DEPARTMENT',
  SDS_REQUEST_OTHER_DEPARTMENT: 'SDS_REQUEST_OTHER_DEPARTMENT',
  CHECK_LIST_READ: 'CHECK_LIST_READ',
  CHECK_LIST_REQUEST: 'CHECK_LIST_REQUEST',
  CHECK_LIST_EXPORT: 'CHECK_LIST_EXPORT',
  CHECK_LIST_CREATE: 'CHECK_LIST_CREATE',
  CHECK_LIST_DELETE: 'CHECK_LIST_DELETE',
  CHECK_LIST_EDIT: 'CHECK_LIST_EDIT',
  CREATE_SIMPLE_DOWNLOAD: 'CREATE_SIMPLE_DOWNLOAD',
  TAG_CREATE: 'TAG_CREATE',
  TAG_EDIT: 'TAG_EDIT',
  TAG_DELETE: 'TAG_DELETE',
  DEPARTMENT_CREATE: 'DEPARTMENT_CREATE',
  DEPARTMENT_EDIT: 'DEPARTMENT_EDIT',
  DEPARTMENT_DELETE: 'DEPARTMENT_DELETE',
  USER_EDIT: 'USER_EDIT',
  USER_INVITE: 'USER_INVITE',
  USER_DELETE: 'USER_DELETE',
} as const;
export type Permission = (typeof PERMISSIONS)[keyof typeof PERMISSIONS];

const ROLE_PERMISSIONS_DESCRIPTION = {
  [RoleId.USER_VIEWER.value]: [
    {
      feature: 'SDS',
      actions: ['閲覧'],
    },
    {
      feature: 'チェックリスト',
      actions: ['閲覧'],
    },
  ],
  [RoleId.USER_EDITOR.value]: [
    {
      feature: 'SDS',
      actions: [
        '閲覧',
        '編集(自部署)',
        '削除',
        'アップロード',
        'エクスポート',
        '再データ化依頼(自部署)',
      ],
    },
    {
      feature: 'チェックリスト',
      actions: ['閲覧', 'エクスポート'],
    },
    {
      feature: 'CREATE SIMPLE',
      actions: ['ダウンロード'],
    },
    {
      feature: 'タグ',
      actions: ['作成', '編集', '削除'],
    },
  ],
  [RoleId.USER_ADMIN.value]: [
    {
      feature: 'SDS',
      actions: [
        '閲覧',
        '編集(自部署)',
        '編集(他部署)',
        '削除',
        'アップロード',
        'エクスポート',
        '再データ化依頼(自部署)',
        '再データ化依頼(他部署)',
      ],
    },
    {
      feature: 'チェックリスト',
      actions: ['閲覧', '編集', 'エクスポート', 'リクエスト'],
    },
    {
      feature: 'CREATE SIMPLE',
      actions: ['ダウンロード'],
    },
    {
      feature: 'タグ',
      actions: ['作成', '編集', '削除'],
    },
    {
      feature: 'ホワイトリストIPアドレス',
      actions: ['作成', '削除'],
    },
    {
      feature: '部署',
      actions: ['作成', '編集', '削除'],
    },
    {
      feature: 'ユーザー',
      actions: ['招待', '編集', '削除'],
    },
    {
      feature: '会社',
      actions: ['編集'],
    },
  ],
} as const;

export const usePermissionComposable = () => {
  const userPermissions = computed(() => {
    return readUserProfile(store).role.permissions.map((p) => p.id);
  });

  const hasPermission = (required: Permission | Permission[]) => {
    if (userPermissions.value.includes(PERMISSIONS.ALL_PRIVILEGES)) {
      return true;
    }

    // 配列で渡された場合はすべての権限を持っているかをチェック
    if (Array.isArray(required)) {
      return required.every((p) => userPermissions.value.includes(p));
    }
    return userPermissions.value.includes(required);
  };

  const hasSdsEditPermission = (sdsDepartments: Department[]) => {
    const userDepartmentIds = readUserProfile(store).departments.map(
      (d) => d.uuid
    );
    const sdsDepartmentIds = sdsDepartments.map((d) => d.uuid);
    const hasOwnDepartment = sdsDepartmentIds.some((d) =>
      userDepartmentIds.includes(d)
    );

    if (
      !hasPermission(PERMISSIONS.SDS_EDIT_OWN_DEPARTMENT) &&
      !hasPermission(PERMISSIONS.SDS_EDIT_OTHER_DEPARTMENT)
    ) {
      return false;
    }
    if (
      hasOwnDepartment &&
      hasPermission(PERMISSIONS.SDS_EDIT_OWN_DEPARTMENT)
    ) {
      return true;
    }
    if (
      !hasOwnDepartment &&
      hasPermission(PERMISSIONS.SDS_EDIT_OTHER_DEPARTMENT)
    ) {
      return true;
    }
    if (sdsDepartments.length === 0) {
      return true;
    }
    return false;
  };

  const hasSdsRequestPermission = (sdsDepartments: Department[]) => {
    const userDepartmentIds = readUserProfile(store).departments.map(
      (d) => d.uuid
    );
    const sdsDepartmentIds = sdsDepartments.map((d) => d.uuid);
    const hasOwnDepartment = sdsDepartmentIds.some((d) =>
      userDepartmentIds.includes(d)
    );

    if (
      !hasPermission(PERMISSIONS.SDS_REQUEST_OWN_DEPARTMENT) &&
      !hasPermission(PERMISSIONS.SDS_REQUEST_OTHER_DEPARTMENT)
    ) {
      return false;
    }
    if (
      hasOwnDepartment &&
      hasPermission(PERMISSIONS.SDS_REQUEST_OWN_DEPARTMENT)
    ) {
      return true;
    }
    if (
      !hasOwnDepartment &&
      hasPermission(PERMISSIONS.SDS_REQUEST_OTHER_DEPARTMENT)
    ) {
      return true;
    }
    if (sdsDepartments.length === 0) {
      return true;
    }
    return false;
  };

  return {
    hasPermission,
    hasSdsEditPermission,
    hasSdsRequestPermission,
    PERMISSIONS,
    ROLE_PERMISSIONS_DESCRIPTION,
  };
};
