import en from '@/locales/en.json';
import ja from '@/locales/ja.json';
import { store } from '@/store/index';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en,
  ja,
};

export const i18n = new VueI18n({
  locale: store.state.main.locale, // set locale
  messages,
});
