export class Section2GHSItemId {
  private static readonly _values: Section2GHSItemId[] = [];

  static readonly EXPLOSIVES = new Section2GHSItemId(
    1000000,
    '火薬類・爆発物 / Explosives',
    '火薬類・爆発物',
    'Explosives'
  );
  static readonly FLAMMABLE_GASES = new Section2GHSItemId(
    1001000,
    '可燃性・引火性ガス / Flammable gases （including chemically unstable gases）',
    '可燃性・引火性ガス',
    'Flammable gases （including chemically unstable gases）'
  );
  static readonly AEROSOLS = new Section2GHSItemId(
    1002000,
    '可燃性・引火性エアゾール / Aerosols',
    '可燃性・引火性エアゾール',
    'Aerosols'
  );
  static readonly OXIDIZING_GASES = new Section2GHSItemId(
    1003000,
    '支燃性・酸化性ガス / Oxidizing gases',
    '支燃性・酸化性ガス',
    'Oxidizing gases'
  );
  static readonly GASES_UNDER_PRESSURE = new Section2GHSItemId(
    1004000,
    '高圧ガス / Gases under pressure',
    '高圧ガス',
    'Gases under pressure'
  );
  static readonly FLAMMABLE_LIQUIDS = new Section2GHSItemId(
    1005000,
    '引火性液体 / Flammable liquids',
    '引火性液体',
    'Flammable liquids'
  );
  static readonly FLAMMABLE_SOLIDS = new Section2GHSItemId(
    1006000,
    '可燃性固体 / Flammable solids',
    '可燃性固体',
    'Flammable solids'
  );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES = new Section2GHSItemId(
    1007000,
    '自己反応性化学品 / Self-reactive substances and mixtures',
    '自己反応性化学品',
    'Self-reactive substances and mixtures'
  );
  static readonly PYROPHORIC_LIQUIDS = new Section2GHSItemId(
    1008000,
    '自然発火性液体 / Pyrophoric liquids',
    '自然発火性液体',
    'Pyrophoric liquids'
  );
  static readonly PYROPHORIC_SOLIDS = new Section2GHSItemId(
    1009000,
    '自然発火性固体 / Pyrophoric solids',
    '自然発火性固体',
    'Pyrophoric solids'
  );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES = new Section2GHSItemId(
    1010000,
    '自己発熱性化学品 / Self-heating substances and mixtures',
    '自己発熱性化学品',
    'Self-heating substances and mixtures'
  );
  static readonly SUBSTANCES_AND_MIXTURES = new Section2GHSItemId(
    1011000,
    '水反応可燃性化学品 / Substances and mixtures which, in contact with water, emit flamm',
    '水反応可燃性化学品',
    'Substances and mixtures which, in contact with water, emit flamm'
  );
  static readonly OXIDIZING_LIQUIDS = new Section2GHSItemId(
    1012000,
    '酸化性液体 / Oxidizing liquids',
    '酸化性液体',
    'Oxidizing liquids'
  );
  static readonly OXIDIZING_SOLIDS = new Section2GHSItemId(
    1013000,
    '酸化性固体 / Oxidizing solids',
    '酸化性固体',
    'Oxidizing solids'
  );
  static readonly ORGANIC_PEROXIDES = new Section2GHSItemId(
    1014000,
    '有機過酸化物 / Organic peroxides',
    '有機過酸化物',
    'Organic peroxides'
  );
  static readonly CORROSIVE_TO_METALS = new Section2GHSItemId(
    1015000,
    '金属腐食性物質 / Corrosive to metals',
    '金属腐食性物質',
    'Corrosive to metals'
  );
  static readonly DESENSITIZED_EXPLOSIVES = new Section2GHSItemId(
    1016000,
    '鈍性化爆発物 / Desensitized explosives',
    '鈍性化爆発物',
    'Desensitized explosives'
  );
  static readonly ACUTE_TOXICITY_ORAL = new Section2GHSItemId(
    1017000,
    '急性毒性（経口） / Acute toxicity （Oral）',
    '急性毒性（経口）',
    'Acute toxicity （Oral）'
  );
  static readonly ACUTE_TOXICITY_DERMAL = new Section2GHSItemId(
    1018000,
    '急性毒性（経皮） / Acute toxicity （Dermal）',
    '急性毒性（経皮）',
    'Acute toxicity （Dermal）'
  );
  static readonly ACUTE_TOXICITY_INHALATION_GASES = new Section2GHSItemId(
    1019000,
    '急性毒性（吸入：気体・ガス） / Acute toxicity （Inhalation: Gases）',
    '急性毒性（吸入：気体・ガス）',
    'Acute toxicity （Inhalation: Gases）'
  );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS = new Section2GHSItemId(
    1020000,
    '急性毒性（吸入：蒸気） / Acute toxicity （Inhalation: Vapours）',
    '急性毒性（吸入：蒸気）',
    'Acute toxicity （Inhalation: Vapours）'
  );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS = new Section2GHSItemId(
    1021000,
    '急性毒性（吸入：粉じん） / Acute toxicity （Inhalation: Dusts）',
    '急性毒性（吸入：粉じん）',
    'Acute toxicity （Inhalation: Dusts）'
  );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS = new Section2GHSItemId(
    1022000,
    '急性毒性（吸入：ミスト） / Acute toxicity （Inhalation: Mists）',
    '急性毒性（吸入：ミスト）',
    'Acute toxicity （Inhalation: Mists）'
  );
  static readonly SKIN_CORROSION_IRRITATION = new Section2GHSItemId(
    1023000,
    '皮膚腐食性・刺激性 / Skin corrosion/irritation',
    '皮膚腐食性・刺激性',
    'Skin corrosion/irritation'
  );
  static readonly SERIOUS_EYE_DAMAGE = new Section2GHSItemId(
    1024000,
    '眼に対する重篤な損傷・眼刺激性 / Serious eye damage/eye irritation',
    '眼に対する重篤な損傷・眼刺激性',
    'Serious eye damage/eye irritation'
  );
  static readonly RESPIRATORY_SENSITIZATION = new Section2GHSItemId(
    1025000,
    '呼吸器感作性 / Respiratory sensitization',
    '呼吸器感作性',
    'Respiratory sensitization'
  );
  static readonly SKIN_SENSITIZATION = new Section2GHSItemId(
    1026000,
    '皮膚感作性 / Skin sensitization',
    '皮膚感作性',
    'Skin sensitization'
  );
  static readonly GERM_CELL_MUTAGENICITY = new Section2GHSItemId(
    1027000,
    '生殖細胞変異原性 / Germ cell mutagenicity',
    '生殖細胞変異原性',
    'Germ cell mutagenicity'
  );
  static readonly CARCINOGENICITY = new Section2GHSItemId(
    1028000,
    '発がん性 / Carcinogenicity',
    '発がん性',
    'Carcinogenicity'
  );
  static readonly REPRODUCTIVE_TOXICITY = new Section2GHSItemId(
    1029000,
    '生殖毒性 / Reproductive toxicity',
    '生殖毒性',
    'Reproductive toxicity'
  );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE =
    new Section2GHSItemId(
      1030000,
      '特定標的臓器・全身毒性（単回ばく露） / Specific target organ toxicity - Single exposure',
      '特定標的臓器・全身毒性（単回ばく露）',
      'Specific target organ toxicity - Single exposure'
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE =
    new Section2GHSItemId(
      1031000,
      '特定標的臓器・全身毒性（反復ばく露） / Specific target organ toxicity - Repeated exposure',
      '特定標的臓器・全身毒性（反復ばく露）',
      'Specific target organ toxicity - Repeated exposure'
    );
  static readonly ASPIRATION_HAZARD = new Section2GHSItemId(
    1032000,
    '吸引性呼吸器有害性・誤えん有害性 / Aspiration hazard',
    '吸引性呼吸器有害性・誤えん有害性',
    'Aspiration hazard'
  );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE =
    new Section2GHSItemId(
      1033000,
      '水生環境急性有害性（短期急性） / Hazardous to the aquatic environment (Acute)・aquatic toxicity (Acute)',
      '水生環境急性有害性（短期急性）',
      'Hazardous to the aquatic environment (Acute)・aquatic toxicity (Acute)'
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM =
    new Section2GHSItemId(
      1034000,
      '水生環境慢性有害性（長期慢性） / Hazardous to the aquatic environment (Long-term・Chronic)・aquatic toxicity (Long-term)',
      '水生環境慢性有害性（長期慢性）',
      'Hazardous to the aquatic environment (Long-term・Chronic)・aquatic toxicity (Long-term)'
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER = new Section2GHSItemId(
    1035000,
    'オゾン層への有害性 / Hazardous to the ozone layer',
    'オゾン層への有害性',
    'Hazardous to the ozone layer'
  );

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string
  ) {
    Section2GHSItemId._values.push(this);
  }

  static fromId(value: number): Section2GHSItemId {
    const res = Section2GHSItemId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return Section2GHSItemId.EXPLOSIVES;
    }
  }

  static values(): Section2GHSItemId[] {
    return Section2GHSItemId._values;
  }
}
