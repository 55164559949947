export class SourceTypeId {
  private static readonly _values: SourceTypeId[] = [];

  static readonly BATCH = new SourceTypeId(0, 'batch');
  static readonly USER = new SourceTypeId(1, 'user');

  private constructor(readonly value: number, readonly text: string) {
    SourceTypeId._values.push(this);
  }

  static fromSourceTypeId(value: number): SourceTypeId {
    const res = SourceTypeId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return SourceTypeId.BATCH;
    }
  }

  static values(): SourceTypeId[] {
    return SourceTypeId._values;
  }
}
