import { Section2GHSItemId } from './Section2GHSItemId';

export class Section2GHSHazardId {
  private static readonly _values: Section2GHSHazardId[] = [];

  static readonly DEFAULT = new Section2GHSHazardId(
    0,
    '分類できない・該当データなし ; Classification not possible (No data)',
    '分類できない・該当データなし',
    'Classification not possible (No data)',
    0
  );
  static readonly REPRODUCTIVE_TOXICITY_EFFECTS_ON_OR_THROUGH_LACTATION =
    new Section2GHSHazardId(
      3029000,
      '授乳に対するまたは授乳を介した影響 / 授乳影響 ; Effects on or via lactation',
      '授乳に対するまたは授乳を介した影響 / 授乳影響',
      'Effects on or via lactation',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly REPRODUCTIVE_TOXICITY_MALFORMATIONS = new Section2GHSHazardId(
    3029001,
    '奇形 ; Malformations',
    '奇形',
    'Malformations',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_WEIGHT_LOSS = new Section2GHSHazardId(
    3029002,
    '体重減少 ; Weight Loss',
    '体重減少',
    'Weight Loss',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_HYDROCEPHALUS = new Section2GHSHazardId(
    3029003,
    '水頭症 ; Hydrocephalus',
    '水頭症',
    'Hydrocephalus',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_OSSIFICATION_DELAY =
    new Section2GHSHazardId(
      3029004,
      '骨化遅延 ; Delay of ossification',
      '骨化遅延',
      'Delay of ossification',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly REPRODUCTIVE_TOXICITY_DEVELOPMENTAL_EFFECTS =
    new Section2GHSHazardId(
      3029005,
      '発生への影響 ; Developmental Effects',
      '発生への影響',
      'Developmental Effects',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly REPRODUCTIVE_TOXICITY_ADVERSE_EFFECTS_ON_FERTILITY_OR_FETUS =
    new Section2GHSHazardId(
      3029006,
      '生殖能又は胎児への悪影響のおそれ ; Adverse effects on fertility or fetus',
      '生殖能又は胎児への悪影響のおそれ',
      'Adverse effects on fertility or fetus',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly REPRODUCTIVE_TOXICITY_SUSPECTED_ADVERSE_EFFECTS_ON_FERTILITY_OR_FETUS =
    new Section2GHSHazardId(
      3029007,
      '生殖能又は胎児への悪影響のおそれの疑い ; Suspected adverse effects on fertility or fetus',
      '生殖能又は胎児への悪影響のおそれの疑い',
      'Suspected adverse effects on fertility or fetus',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_RESPIRATORY_TRACT_IRRITATION =
    new Section2GHSHazardId(
      3030000,
      '気道刺激性 ; Respiratory Tract Irritation',
      '気道刺激性',
      'Respiratory Tract Irritation',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ANESTHETIC_PROPERTIES =
    new Section2GHSHazardId(
      3030001,
      '麻酔性 ; Anesthesia',
      '麻酔性',
      'Anesthesia',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ANESTHETIC_EFFECTS =
    new Section2GHSHazardId(
      3030002,
      '麻酔作用 ; Anesthesia',
      '麻酔作用',
      'Anesthesia',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ANESTHETIC_EFFECT =
    new Section2GHSHazardId(
      3030003,
      '麻酔効果 ; Anesthesia Effect',
      '麻酔効果',
      'Anesthesia Effect',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_MAY_CAUSE_DROWSINESS_OR_DIZZINESS =
    new Section2GHSHazardId(
      3030004,
      '眠気又はめまいのおそれ ; May Cause Drowsiness or Dizziness',
      '眠気又はめまいのおそれ',
      'May Cause Drowsiness or Dizziness',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_RESPIRATORY_SYSTEM =
    new Section2GHSHazardId(
      3030005,
      '呼吸器系 ; Respiratory System',
      '呼吸器系',
      'Respiratory System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_RESPIRATORY_TRACT =
    new Section2GHSHazardId(
      3030006,
      '呼吸器 ; Respiratory apparatus',
      '呼吸器',
      'Respiratory apparatus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BRONCHIAL =
    new Section2GHSHazardId(
      3030007,
      '気管支 ; Bronchi',
      '気管支',
      'Bronchi',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_AIRWAY =
    new Section2GHSHazardId(
      3030008,
      '気道 ; Respiratory tract',
      '気道',
      'Respiratory tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_UPPER_AIRWAY =
    new Section2GHSHazardId(
      3030009,
      '上気道 ; Upper respiratory tract',
      '上気道',
      'Upper respiratory tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_NASAL_CAVITY =
    new Section2GHSHazardId(
      3030010,
      '鼻腔 ; Nasal Cavity',
      '鼻腔',
      'Nasal Cavity',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_NASAL_MUCOSA =
    new Section2GHSHazardId(
      3030011,
      '鼻粘膜 ; Nasal Mucosa',
      '鼻粘膜',
      'Nasal Mucosa',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_NASOPHARYNX =
    new Section2GHSHazardId(
      3030012,
      '鼻咽頭 ; Nasopharynx',
      '鼻咽頭',
      'Nasopharynx',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_NASAL_TISSUE =
    new Section2GHSHazardId(
      3030013,
      '鼻組織 ; Nasal Tissue',
      '鼻組織',
      'Nasal Tissue',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LOWER_AIRWAY =
    new Section2GHSHazardId(
      3030014,
      '下気道 ; Lower Respiratory Tract',
      '下気道',
      'Lower Respiratory Tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LARYNX =
    new Section2GHSHazardId(
      3030015,
      '喉頭 ; Larynx',
      '喉頭',
      'Larynx',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_TRACHEA =
    new Section2GHSHazardId(
      3030016,
      '気管 ; Trachea',
      '気管',
      'Trachea',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LUNG =
    new Section2GHSHazardId(
      3030017,
      '肺 ; Lung',
      '肺',
      'Lung',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PNEUMOCONIOSIS =
    new Section2GHSHazardId(
      3030018,
      'じん肺 ; Pneumoconiosis',
      'じん肺',
      'Pneumoconiosis',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BRAIN =
    new Section2GHSHazardId(
      3030019,
      '脳 ; Brain',
      '脳',
      'Brain',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SPINAL_CORD =
    new Section2GHSHazardId(
      3030020,
      '脊髄 ; Spinal Cord',
      '脊髄',
      'Spinal Cord',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_NERVOUS =
    new Section2GHSHazardId(
      3030021,
      '神経 ; Nervous',
      '神経',
      'Nervous',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3030022,
      '神経系 ; Nervous System',
      '神経系',
      'Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CENTRAL_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3030023,
      '中枢神経系 ; Central Nervous System',
      '中枢神経系',
      'Central Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CRANIAL_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3030024,
      '脳神経系 ; Cranial Nervous System',
      '脳神経系',
      'Cranial Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_OPTIC_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3030025,
      '視神経系 ; Optic Nervous System',
      '視神経系',
      'Optic Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PERIPHERAL_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3030026,
      '末梢神経系 ; Peripheral Nervous System',
      '末梢神経系',
      'Peripheral Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_AUTONOMIC_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3030027,
      '自律神経系 ; Autonomic Nervous System',
      '自律神経系',
      'Autonomic Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_MOTOR_NERVE =
    new Section2GHSHazardId(
      3030028,
      '運動神経系 ; Motor Nerve',
      '運動神経系',
      'Motor Nerve',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SENSORY_ORGAN =
    new Section2GHSHazardId(
      3030029,
      '感覚器 ; Sensory Organ',
      '感覚器',
      'Sensory Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_VISUAL_SYSTEM =
    new Section2GHSHazardId(
      3030030,
      '視覚系 ; Visual System',
      '視覚系',
      'Visual System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_EYE =
    new Section2GHSHazardId(
      3030031,
      '眼 ; Eyes',
      '眼',
      'Eyes',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_AMPHIBLESTRODE =
    new Section2GHSHazardId(
      3030032,
      '眼網膜 ; Retina',
      '眼網膜',
      'Retina',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_EARING =
    new Section2GHSHazardId(
      3030033,
      '聴覚; Hearing',
      '聴覚',
      'earing',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_AUDITORY_SYSTEM =
    new Section2GHSHazardId(
      3030034,
      '聴覚器 ; Auditory Organ',
      '聴覚器',
      'Auditory Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BLOOD_SYSTEM =
    new Section2GHSHazardId(
      3030035,
      '血液系 ; Blood System',
      '血液系',
      'Blood System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BLOOD_COAGULATION_SYSTEM =
    new Section2GHSHazardId(
      3030036,
      '血液凝固系 ; Blood Coagulation System',
      '血液凝固系',
      'Blood Coagulation System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_HEMATOPOIETIC_SYSTEM =
    new Section2GHSHazardId(
      3030037,
      '造血系 ; Hematopoietic System',
      '造血系',
      'Hematopoietic System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BLOOD =
    new Section2GHSHazardId(
      3030038,
      '血液 ; Blood',
      '血液',
      'Blood',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BONE_MARROW =
    new Section2GHSHazardId(
      3030039,
      '骨髄 ; Bone Marrow',
      '骨髄',
      'Bone Marrow',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LYMPHATIC_SYSTEM =
    new Section2GHSHazardId(
      3030040,
      'リンパ系 ; Lymphatic System',
      'リンパ系',
      'Lymphatic System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LYMPH_NODE =
    new Section2GHSHazardId(
      3030041,
      'リンパ節 ; Lymph Node',
      'リンパ節',
      'Lymph Node',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_MESENTERIC_LYMPH_NODES =
    new Section2GHSHazardId(
      3030042,
      '腸間膜リンパ節 ; Mesenteric Lymph Nodes',
      '腸間膜リンパ節',
      'Mesenteric Lymph Nodes',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_THYMUS =
    new Section2GHSHazardId(
      3030043,
      '胸腺 ; Thymus',
      '胸腺',
      'Thymus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SPLEEN =
    new Section2GHSHazardId(
      3030044,
      '脾臓 ; Spleen',
      '脾臓',
      'Spleen',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_IMMUNE_SYSTEM =
    new Section2GHSHazardId(
      3030045,
      '免疫系 ; Immune System',
      '免疫系',
      'Immune System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CIRCULATORY_SYSTEM =
    new Section2GHSHazardId(
      3030046,
      '循環器系 ; Circulatory System',
      '循環器系',
      'Circulatory System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_HEART =
    new Section2GHSHazardId(
      3030047,
      '心臓 ; Heart',
      '心臓',
      'Heart',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CARDIOVASCULAR_SYSTEM =
    new Section2GHSHazardId(
      3030048,
      '心血管系 ; Cardiovascular System',
      '心血管系',
      'Cardiovascular System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_VASCULAR_SYSTEM =
    new Section2GHSHazardId(
      3030049,
      '血管系 ; Blood Vascular System',
      '血管系',
      'Blood Vascular System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CEREBRAL_BLOOD_VESSEL =
    new Section2GHSHazardId(
      3030050,
      '脳血管 ; Cerebral Vascula',
      '脳血管',
      'Cerebral Vascula',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_INTERNAL_ORGAN =
    new Section2GHSHazardId(
      3030051,
      '臓器 ; Internal Organ',
      '臓器',
      'Internal Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_DIGESTIVE_SYSTEM =
    new Section2GHSHazardId(
      3030052,
      '消化器系 ; Digestive System',
      '消化器系',
      'Digestive System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_DIGESTIVE_TRACT =
    new Section2GHSHazardId(
      3030053,
      '消化管 ; Digestive Tract',
      '消化管',
      'Digestive Tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ORAL_CAVITY =
    new Section2GHSHazardId(
      3030054,
      '口腔 ; Oral Cavity',
      '口腔',
      'Oral Cavity',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ESOPHAGUS =
    new Section2GHSHazardId(
      3030055,
      '食道 ; Esophagus',
      '食道',
      'Esophagus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_STOMACH =
    new Section2GHSHazardId(
      3030056,
      '胃 ; Stomach',
      '胃',
      'Stomach',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_INTESTINE =
    new Section2GHSHazardId(
      3030057,
      '腸管 ; Intestinal Tract',
      '腸管',
      'Intestinal Tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SMALL_INTESTINE =
    new Section2GHSHazardId(
      3030058,
      '小腸 ; Small Intestine',
      '小腸',
      'Small Intestine',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_DUODENUM =
    new Section2GHSHazardId(
      3030059,
      '十二指腸 ; Duodenum',
      '十二指腸',
      'Duodenum',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LARGE_INTESTINE =
    new Section2GHSHazardId(
      3030060,
      '大腸 ; Large Intestine',
      '大腸',
      'Large Intestine',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SALIVARY_GLAND =
    new Section2GHSHazardId(
      3030061,
      '唾液腺 ; Salivary Gland',
      '唾液腺',
      'Salivary Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SUBMANDIBULAR_GLAND =
    new Section2GHSHazardId(
      3030062,
      '顎下腺 ; Submandibular Gland',
      '顎下腺',
      'Submandibular Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PANCREAS =
    new Section2GHSHazardId(
      3030063,
      '膵臓 ; Pancreas',
      '膵臓',
      'Pancreas',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_OOTH =
    new Section2GHSHazardId(
      3030064,
      '歯; Teeth',
      '歯',
      'eeth',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_UMS =
    new Section2GHSHazardId(
      3030065,
      '歯肉; Gums',
      '歯肉',
      'ums',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PERIODONTAL_TISSUE =
    new Section2GHSHazardId(
      3030066,
      '歯周組織 ; Periodontal Tissue',
      '歯周組織',
      'Periodontal Tissue',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LIVER =
    new Section2GHSHazardId(
      3030067,
      '肝臓 ; Liver',
      '肝臓',
      'Liver',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_GALLBLADDER =
    new Section2GHSHazardId(
      3030068,
      '胆嚢 ; Gallbladder',
      '胆嚢',
      'Gallbladder',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_URINARY_SYSTEM =
    new Section2GHSHazardId(
      3030069,
      '泌尿器 ; Urinary Organs',
      '泌尿器',
      'Urinary Organs',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_KIDNEY =
    new Section2GHSHazardId(
      3030070,
      '腎臓 ; Kidney',
      '腎臓',
      'Kidney',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BLADDER =
    new Section2GHSHazardId(
      3030071,
      '膀胱 ; Vesica',
      '膀胱',
      'Vesica',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_REPRODUCTIVE_SYSTEM =
    new Section2GHSHazardId(
      3030072,
      '生殖器系 ; Reproductive System',
      '生殖器系',
      'Reproductive System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ACCESSORY_REPRODUCTIVE_ORGAN =
    new Section2GHSHazardId(
      3030073,
      '副生殖器 ; Accessory Reproductive Organ',
      '副生殖器',
      'Accessory Reproductive Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_MALE_REPRODUCTIVE_ORGAN =
    new Section2GHSHazardId(
      3030074,
      '男性生殖器 ; Male Reproductive Organ',
      '男性生殖器',
      'Male Reproductive Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_TESTIS =
    new Section2GHSHazardId(
      3030075,
      '精巣 ; Testis',
      '精巣',
      'Testis',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_EPIDIDYMIS =
    new Section2GHSHazardId(
      3030076,
      '精巣上体 ; Epididymis',
      '精巣上体',
      'Epididymis',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_TESTICULAR_APPENDAGE =
    new Section2GHSHazardId(
      3030077,
      '精巣付属器 ; Testicular Appendage',
      '精巣付属器',
      'Testicular Appendage',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PROSTATE =
    new Section2GHSHazardId(
      3030078,
      '前立腺 ; Prostate',
      '前立腺',
      'Prostate',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_FEMALE_REPRODUCTIVE_ORGAN =
    new Section2GHSHazardId(
      3030079,
      '女性生殖器 ; Female Reproductive Organ',
      '女性生殖器',
      'Female Reproductive Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_OVARY =
    new Section2GHSHazardId(
      3030080,
      '卵巣 ; Ovary',
      '卵巣',
      'Ovary',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_UTERUS =
    new Section2GHSHazardId(
      3030081,
      '子宮 ; Uterus',
      '子宮',
      'Uterus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ENDOCRINE_SYSTEM =
    new Section2GHSHazardId(
      3030082,
      '内分泌系 ; Endocrine System',
      '内分泌系',
      'Endocrine System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PITUITARY_GLAND =
    new Section2GHSHazardId(
      3030083,
      '下垂体 ; Pituitary Gland',
      '下垂体',
      'Pituitary Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_THYROID_GLAND =
    new Section2GHSHazardId(
      3030084,
      '甲状腺 ; Glandula Thyreoidea',
      '甲状腺',
      'Glandula Thyreoidea',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PARATHYROID_GLAND =
    new Section2GHSHazardId(
      3030085,
      '上皮小体 ; Parathyroid Gland',
      '上皮小体',
      'Parathyroid Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ADRENAL_GLAND =
    new Section2GHSHazardId(
      3030086,
      '副腎 ; Adrenal Gland',
      '副腎',
      'Adrenal Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SKIN =
    new Section2GHSHazardId(
      3030087,
      '皮膚 ; Skin',
      '皮膚',
      'Skin',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SKIN_APPENDAGE =
    new Section2GHSHazardId(
      3030088,
      '皮膚付属器 ; Skin Appendages',
      '皮膚付属器',
      'Skin Appendages',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BODY_HAIR =
    new Section2GHSHazardId(
      3030089,
      '体毛 ; Body Hair',
      '体毛',
      'Body Hair',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_MUSCLE_SYSTEM =
    new Section2GHSHazardId(
      3030090,
      '筋肉系 ; Musclar System',
      '筋肉系',
      'Musclar System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CRURAL_MUSCLE =
    new Section2GHSHazardId(
      3030091,
      '下腿筋 ; Crural Muscle',
      '下腿筋',
      'Crural Muscle',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SKELETAL_MUSCLE =
    new Section2GHSHazardId(
      3030092,
      '骨格筋 ; Skeletal Muscle',
      '骨格筋',
      'Skeletal Muscle',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SKELETAL_SYSTEM =
    new Section2GHSHazardId(
      3030093,
      '骨格系 ; Skeletal System',
      '骨格系',
      'Skeletal System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_OSSIFICATION_DELAY =
    new Section2GHSHazardId(
      3030094,
      '骨化遅延 ; Delay of Ossification',
      '骨化遅延',
      'Delay of Ossification',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BONE =
    new Section2GHSHazardId(
      3030095,
      '骨 ; Bone',
      '骨',
      'Bone',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_JOINT =
    new Section2GHSHazardId(
      3030096,
      '関節 ; Bone Joint',
      '関節',
      'Bone Joint',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SYSTEMIC_TOXICITY =
    new Section2GHSHazardId(
      3030097,
      '全身毒性 ; Systemic Toxicity',
      '全身毒性',
      'Systemic Toxicity',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_WHOLE_BODY =
    new Section2GHSHazardId(
      3030098,
      '全身 ; Whole Body',
      '全身',
      'Whole Body',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_INHALATION =
    new Section2GHSHazardId(
      3030099,
      '吸入 ; Inhalation',
      '吸入',
      'Inhalation',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_WHOLE_BODY_INHALATION =
    new Section2GHSHazardId(
      3030100,
      '全身（吸入） ; Whole body (Inhalation)',
      '全身（吸入）',
      'Whole body (Inhalation)',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_RESPIRATORY_TRACT_INHALATION =
    new Section2GHSHazardId(
      3030101,
      '呼吸器（吸入） ; Respiratory Tract (Inhalation)',
      '呼吸器（吸入）',
      'Respiratory Tract (Inhalation)',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LUNG_INHALATION =
    new Section2GHSHazardId(
      3030102,
      '肺（吸入） ; Lung (Inhalation)',
      '肺（吸入）',
      'Lung (Inhalation)',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_RESPIRATORY_TRACT_IRRITATION =
    new Section2GHSHazardId(
      3031000,
      '気道刺激性 ; Respiratory Tract Irritation',
      '気道刺激性',
      'Respiratory Tract Irritation',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ANESTHETIC_PROPERTIES =
    new Section2GHSHazardId(
      3031001,
      '麻酔性 ; Anesthesia',
      '麻酔性',
      'Anesthesia',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ANESTHETIC_EFFECTS =
    new Section2GHSHazardId(
      3031002,
      '麻酔作用 ; Anesthesia',
      '麻酔作用',
      'Anesthesia',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ANESTHETIC_EFFECT =
    new Section2GHSHazardId(
      3031003,
      '麻酔効果 ; Anesthesia Effect',
      '麻酔効果',
      'Anesthesia Effect',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_MAY_CAUSE_DROWSINESS_OR_DIZZINESS =
    new Section2GHSHazardId(
      3031004,
      '眠気又はめまいのおそれ ; May Cause Drowsiness or Dizziness',
      '眠気又はめまいのおそれ',
      'May Cause Drowsiness or Dizziness',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_RESPIRATORY_SYSTEM =
    new Section2GHSHazardId(
      3031005,
      '呼吸器系 ; Respiratory System',
      '呼吸器系',
      'Respiratory System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_RESPIRATORY_TRACT =
    new Section2GHSHazardId(
      3031006,
      '呼吸器 ; Respiratory apparatus',
      '呼吸器',
      'Respiratory apparatus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BRONCHIAL =
    new Section2GHSHazardId(
      3031007,
      '気管支 ; Bronchi',
      '気管支',
      'Bronchi',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_AIRWAY =
    new Section2GHSHazardId(
      3031008,
      '気道 ; Respiratory tract',
      '気道',
      'Respiratory tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_UPPER_AIRWAY =
    new Section2GHSHazardId(
      3031009,
      '上気道 ; Upper respiratory tract',
      '上気道',
      'Upper respiratory tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_NASAL_CAVITY =
    new Section2GHSHazardId(
      3031010,
      '鼻腔 ; Nasal Cavity',
      '鼻腔',
      'Nasal Cavity',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_NASAL_MUCOSA =
    new Section2GHSHazardId(
      3031011,
      '鼻粘膜 ; Nasal Mucosa',
      '鼻粘膜',
      'Nasal Mucosa',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_NASOPHARYNX =
    new Section2GHSHazardId(
      3031012,
      '鼻咽頭 ; Nasopharynx',
      '鼻咽頭',
      'Nasopharynx',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_NASAL_TISSUE =
    new Section2GHSHazardId(
      3031013,
      '鼻組織 ; Nasal Tissue',
      '鼻組織',
      'Nasal Tissue',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LOWER_AIRWAY =
    new Section2GHSHazardId(
      3031014,
      '下気道 ; Lower Respiratory Tract',
      '下気道',
      'Lower Respiratory Tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LARYNX =
    new Section2GHSHazardId(
      3031015,
      '喉頭 ; Larynx',
      '喉頭',
      'Larynx',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_TRACHEA =
    new Section2GHSHazardId(
      3031016,
      '気管 ; Trachea',
      '気管',
      'Trachea',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LUNG =
    new Section2GHSHazardId(
      3031017,
      '肺 ; Lung',
      '肺',
      'Lung',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PNEUMOCONIOSIS =
    new Section2GHSHazardId(
      3031018,
      'じん肺 ; Pneumoconiosis',
      'じん肺',
      'Pneumoconiosis',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BRAIN =
    new Section2GHSHazardId(
      3031019,
      '脳 ; Brain',
      '脳',
      'Brain',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SPINAL_CORD =
    new Section2GHSHazardId(
      3031020,
      '脊髄 ; Spinal Cord',
      '脊髄',
      'Spinal Cord',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_NERVOUS =
    new Section2GHSHazardId(
      3031021,
      '神経 ; Nervous',
      '神経',
      'Nervous',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3031022,
      '神経系 ; Nervous System',
      '神経系',
      'Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CENTRAL_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3031023,
      '中枢神経系 ; Central Nervous System',
      '中枢神経系',
      'Central Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CRANIAL_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3031024,
      '脳神経系 ; Cranial Nervous System',
      '脳神経系',
      'Cranial Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_OPTIC_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3031025,
      '視神経系 ; Optic Nervous System',
      '視神経系',
      'Optic Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PERIPHERAL_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3031026,
      '末梢神経系 ; Peripheral Nervous System',
      '末梢神経系',
      'Peripheral Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_AUTONOMIC_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3031027,
      '自律神経系 ; Autonomic Nervous System',
      '自律神経系',
      'Autonomic Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_MOTOR_NERVE =
    new Section2GHSHazardId(
      3031028,
      '運動神経系 ; Motor Nerve',
      '運動神経系',
      'Motor Nerve',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SENSORY_ORGAN =
    new Section2GHSHazardId(
      3031029,
      '感覚器 ; Sensory Organ',
      '感覚器',
      'Sensory Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_VISUAL_SYSTEM =
    new Section2GHSHazardId(
      3031030,
      '視覚系 ; Visual System',
      '視覚系',
      'Visual System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_EYE =
    new Section2GHSHazardId(
      3031031,
      '眼 ; Eyes',
      '眼',
      'Eyes',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_AMPHIBLESTRODE =
    new Section2GHSHazardId(
      3031032,
      '眼網膜 ; Retina',
      '眼網膜',
      'Retina',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_EARING =
    new Section2GHSHazardId(
      3031033,
      '聴覚; Hearing',
      '聴覚',
      'earing',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_AUDITORY_SYSTEM =
    new Section2GHSHazardId(
      3031034,
      '聴覚器 ; Auditory Organ',
      '聴覚器',
      'Auditory Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BLOOD_SYSTEM =
    new Section2GHSHazardId(
      3031035,
      '血液系 ; Blood System',
      '血液系',
      'Blood System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BLOOD_COAGULATION_SYSTEM =
    new Section2GHSHazardId(
      3031036,
      '血液凝固系 ; Blood Coagulation System',
      '血液凝固系',
      'Blood Coagulation System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_HEMATOPOIETIC_SYSTEM =
    new Section2GHSHazardId(
      3031037,
      '造血系 ; Hematopoietic System',
      '造血系',
      'Hematopoietic System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BLOOD =
    new Section2GHSHazardId(
      3031038,
      '血液 ; Blood',
      '血液',
      'Blood',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BONE_MARROW =
    new Section2GHSHazardId(
      3031039,
      '骨髄 ; Bone Marrow',
      '骨髄',
      'Bone Marrow',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LYMPHATIC_SYSTEM =
    new Section2GHSHazardId(
      3031040,
      'リンパ系 ; Lymphatic System',
      'リンパ系',
      'Lymphatic System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LYMPH_NODE =
    new Section2GHSHazardId(
      3031041,
      'リンパ節 ; Lymph Node',
      'リンパ節',
      'Lymph Node',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_MESENTERIC_LYMPH_NODES =
    new Section2GHSHazardId(
      3031042,
      '腸間膜リンパ節 ; Mesenteric Lymph Nodes',
      '腸間膜リンパ節',
      'Mesenteric Lymph Nodes',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_THYMUS =
    new Section2GHSHazardId(
      3031043,
      '胸腺 ; Thymus',
      '胸腺',
      'Thymus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SPLEEN =
    new Section2GHSHazardId(
      3031044,
      '脾臓 ; Spleen',
      '脾臓',
      'Spleen',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_IMMUNE_SYSTEM =
    new Section2GHSHazardId(
      3031045,
      '免疫系 ; Immune System',
      '免疫系',
      'Immune System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CIRCULATORY_SYSTEM =
    new Section2GHSHazardId(
      3031046,
      '循環器系 ; Circulatory System',
      '循環器系',
      'Circulatory System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_HEART =
    new Section2GHSHazardId(
      3031047,
      '心臓 ; Heart',
      '心臓',
      'Heart',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CARDIOVASCULAR_SYSTEM =
    new Section2GHSHazardId(
      3031048,
      '心血管系 ; Cardiovascular System',
      '心血管系',
      'Cardiovascular System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_VASCULAR_SYSTEM =
    new Section2GHSHazardId(
      3031049,
      '血管系 ; Blood Vascular System',
      '血管系',
      'Blood Vascular System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CEREBRAL_BLOOD_VESSEL =
    new Section2GHSHazardId(
      3031050,
      '脳血管 ; Cerebral Vascula',
      '脳血管',
      'Cerebral Vascula',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_INTERNAL_ORGAN =
    new Section2GHSHazardId(
      3031051,
      '臓器 ; Internal Organ',
      '臓器',
      'Internal Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_DIGESTIVE_SYSTEM =
    new Section2GHSHazardId(
      3031052,
      '消化器系 ; Digestive System',
      '消化器系',
      'Digestive System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_DIGESTIVE_TRACT =
    new Section2GHSHazardId(
      3031053,
      '消化管 ; Digestive Tract',
      '消化管',
      'Digestive Tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ORAL_CAVITY =
    new Section2GHSHazardId(
      3031054,
      '口腔 ; Oral Cavity',
      '口腔',
      'Oral Cavity',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ESOPHAGUS =
    new Section2GHSHazardId(
      3031055,
      '食道 ; Esophagus',
      '食道',
      'Esophagus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_STOMACH =
    new Section2GHSHazardId(
      3031056,
      '胃 ; Stomach',
      '胃',
      'Stomach',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_INTESTINE =
    new Section2GHSHazardId(
      3031057,
      '腸管 ; Intestinal Tract',
      '腸管',
      'Intestinal Tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SMALL_INTESTINE =
    new Section2GHSHazardId(
      3031058,
      '小腸 ; Small Intestine',
      '小腸',
      'Small Intestine',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_DUODENUM =
    new Section2GHSHazardId(
      3031059,
      '十二指腸 ; Duodenum',
      '十二指腸',
      'Duodenum',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LARGE_INTESTINE =
    new Section2GHSHazardId(
      3031060,
      '大腸 ; Large Intestine',
      '大腸',
      'Large Intestine',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SALIVARY_GLAND =
    new Section2GHSHazardId(
      3031061,
      '唾液腺 ; Salivary Gland',
      '唾液腺',
      'Salivary Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SUBMANDIBULAR_GLAND =
    new Section2GHSHazardId(
      3031062,
      '顎下腺 ; Submandibular Gland',
      '顎下腺',
      'Submandibular Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PANCREAS =
    new Section2GHSHazardId(
      3031063,
      '膵臓 ; Pancreas',
      '膵臓',
      'Pancreas',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_OOTH =
    new Section2GHSHazardId(
      3031064,
      '歯; Teeth',
      '歯',
      'eeth',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_UMS =
    new Section2GHSHazardId(
      3031065,
      '歯肉; Gums',
      '歯肉',
      'ums',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PERIODONTAL_TISSUE =
    new Section2GHSHazardId(
      3031066,
      '歯周組織 ; Periodontal Tissue',
      '歯周組織',
      'Periodontal Tissue',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LIVER =
    new Section2GHSHazardId(
      3031067,
      '肝臓 ; Liver',
      '肝臓',
      'Liver',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_GALLBLADDER =
    new Section2GHSHazardId(
      3031068,
      '胆嚢 ; Gallbladder',
      '胆嚢',
      'Gallbladder',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_URINARY_SYSTEM =
    new Section2GHSHazardId(
      3031069,
      '泌尿器 ; Urinary Organs',
      '泌尿器',
      'Urinary Organs',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_KIDNEY =
    new Section2GHSHazardId(
      3031070,
      '腎臓 ; Kidney',
      '腎臓',
      'Kidney',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BLADDER =
    new Section2GHSHazardId(
      3031071,
      '膀胱 ; Vesica',
      '膀胱',
      'Vesica',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_REPRODUCTIVE_SYSTEM =
    new Section2GHSHazardId(
      3031072,
      '生殖器系 ; Reproductive System',
      '生殖器系',
      'Reproductive System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ACCESSORY_REPRODUCTIVE_ORGAN =
    new Section2GHSHazardId(
      3031073,
      '副生殖器 ; Accessory Reproductive Organ',
      '副生殖器',
      'Accessory Reproductive Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_MALE_REPRODUCTIVE_ORGAN =
    new Section2GHSHazardId(
      3031074,
      '男性生殖器 ; Male Reproductive Organ',
      '男性生殖器',
      'Male Reproductive Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_TESTIS =
    new Section2GHSHazardId(
      3031075,
      '精巣 ; Testis',
      '精巣',
      'Testis',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_EPIDIDYMIS =
    new Section2GHSHazardId(
      3031076,
      '精巣上体 ; Epididymis',
      '精巣上体',
      'Epididymis',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_TESTICULAR_APPENDAGE =
    new Section2GHSHazardId(
      3031077,
      '精巣付属器 ; Testicular Appendage',
      '精巣付属器',
      'Testicular Appendage',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PROSTATE =
    new Section2GHSHazardId(
      3031078,
      '前立腺 ; Prostate',
      '前立腺',
      'Prostate',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_FEMALE_REPRODUCTIVE_ORGAN =
    new Section2GHSHazardId(
      3031079,
      '女性生殖器 ; Female Reproductive Organ',
      '女性生殖器',
      'Female Reproductive Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_OVARY =
    new Section2GHSHazardId(
      3031080,
      '卵巣 ; Ovary',
      '卵巣',
      'Ovary',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_UTERUS =
    new Section2GHSHazardId(
      3031081,
      '子宮 ; Uterus',
      '子宮',
      'Uterus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ENDOCRINE_SYSTEM =
    new Section2GHSHazardId(
      3031082,
      '内分泌系 ; Endocrine System',
      '内分泌系',
      'Endocrine System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PITUITARY_GLAND =
    new Section2GHSHazardId(
      3031083,
      '下垂体 ; Pituitary Gland',
      '下垂体',
      'Pituitary Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_THYROID_GLAND =
    new Section2GHSHazardId(
      3031084,
      '甲状腺 ; Glandula Thyreoidea',
      '甲状腺',
      'Glandula Thyreoidea',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PARATHYROID_GLAND =
    new Section2GHSHazardId(
      3031085,
      '上皮小体 ; Parathyroid Gland',
      '上皮小体',
      'Parathyroid Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ADRENAL_GLAND =
    new Section2GHSHazardId(
      3031086,
      '副腎 ; Adrenal Gland',
      '副腎',
      'Adrenal Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SKIN =
    new Section2GHSHazardId(
      3031087,
      '皮膚 ; Skin',
      '皮膚',
      'Skin',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SKIN_APPENDAGE =
    new Section2GHSHazardId(
      3031088,
      '皮膚付属器 ; Skin Appendages',
      '皮膚付属器',
      'Skin Appendages',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BODY_HAIR =
    new Section2GHSHazardId(
      3031089,
      '体毛 ; Body Hair',
      '体毛',
      'Body Hair',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_MUSCLE_SYSTEM =
    new Section2GHSHazardId(
      3031090,
      '筋肉系 ; Musclar System',
      '筋肉系',
      'Musclar System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CRURAL_MUSCLE =
    new Section2GHSHazardId(
      3031091,
      '下腿筋 ; Crural Muscle',
      '下腿筋',
      'Crural Muscle',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SKELETAL_MUSCLE =
    new Section2GHSHazardId(
      3031092,
      '骨格筋 ; Skeletal Muscle',
      '骨格筋',
      'Skeletal Muscle',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SKELETAL_SYSTEM =
    new Section2GHSHazardId(
      3031093,
      '骨格系 ; Skeletal System',
      '骨格系',
      'Skeletal System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_OSSIFICATION_DELAY =
    new Section2GHSHazardId(
      3031094,
      '骨化遅延 ; Delay of Ossification',
      '骨化遅延',
      'Delay of Ossification',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BONE =
    new Section2GHSHazardId(
      3031095,
      '骨 ; Bone',
      '骨',
      'Bone',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_JOINT =
    new Section2GHSHazardId(
      3031096,
      '関節 ; Bone Joint',
      '関節',
      'Bone Joint',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SYSTEMIC_TOXICITY =
    new Section2GHSHazardId(
      3031097,
      '全身毒性 ; Systemic Toxicity',
      '全身毒性',
      'Systemic Toxicity',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_WHOLE_BODY =
    new Section2GHSHazardId(
      3031098,
      '全身 ; Whole Body',
      '全身',
      'Whole Body',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_INHALATION =
    new Section2GHSHazardId(
      3031099,
      '吸入 ; Inhalation',
      '吸入',
      'Inhalation',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_WHOLE_BODY_INHALATION =
    new Section2GHSHazardId(
      3031100,
      '全身（吸入） ; Whole body (Inhalation)',
      '全身（吸入）',
      'Whole body (Inhalation)',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_RESPIRATORY_TRACT_INHALATION =
    new Section2GHSHazardId(
      3031101,
      '呼吸器（吸入） ; Respiratory Tract (Inhalation)',
      '呼吸器（吸入）',
      'Respiratory Tract (Inhalation)',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LUNG_INHALATION =
    new Section2GHSHazardId(
      3031102,
      '肺（吸入） ; Lung (Inhalation)',
      '肺（吸入）',
      'Lung (Inhalation)',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string,
    readonly itemId: number
  ) {
    Section2GHSHazardId._values.push(this);
  }

  static fromId(value: number): Section2GHSHazardId {
    const res = Section2GHSHazardId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return Section2GHSHazardId.DEFAULT;
    }
  }

  static values(): Section2GHSHazardId[] {
    return Section2GHSHazardId._values;
  }

  static valuesFromItemId(id: number): Section2GHSHazardId[] {
    return Section2GHSHazardId._values.filter((x) => x.itemId == id);
  }
}
