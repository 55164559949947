import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },

  theme: {
    themes: {
      light: {
        primary: '#057857',
        secondary: '#D9F2E9',
        accent: '#F2D9D8',
        error: '#FF5252',
        cancel: '#9E9E9E',
      },
    },
  },
});
