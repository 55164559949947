import '@babel/polyfill';
// Import Component hooks before component definitions
import { i18n } from '@/plugins/vue-i18n';
import vuetify from '@/plugins/vuetify';
import store from '@/store';
import Vue from 'vue';
import App from './App.vue';
import './component-hooks';
import './plugins/vee-validate';
import './plugins/vuetify';
import router from './router';

require('./styles/styles.scss');
require('./styles/layout.css');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  beforeCreate() {
    store.watch(
      () => store.state.main.locale,
      (newLocale) => {
        i18n.locale = newLocale;
      }
    );
  },
  render: (h) => h(App),
}).$mount('#app');
