import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { mainModule } from './main';
import { State } from './state';

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  plugins: [createPersistedState()],
  modules: {
    main: mainModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
