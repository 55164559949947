export class StatusId {
  private static readonly _values: StatusId[] = [];
  private static _valuesCopy: StatusId[] | null = null;

  static readonly DEFAULT = new StatusId(0, '', 'Default');
  static readonly UPLOADING = new StatusId(10000, '分析前', '分析前');
  static readonly ASSORT = new StatusId(15000, '分析前', '分析前');
  static readonly ASSORT_DONE = new StatusId(20000, '分析前', '分析前');
  static readonly FIRST_CHECK_PROCESSING = new StatusId(
    30000,
    '分析中',
    '分析中'
  );
  static readonly FIRST_CHECK_DONE = new StatusId(40000, '分析中', '分析中');
  static readonly SECOND_CHECK_PROCESSING = new StatusId(
    50000,
    '分析中',
    '分析中'
  );
  static readonly SECOND_CHECK_DONE = new StatusId(
    60000,
    '分析完了',
    '分析完了'
  );
  static readonly ERROR = new StatusId(90000, '分析不可', '分析不可');
  static readonly ERROR_RECONFIRMATION = new StatusId(
    91000,
    '要確認',
    '要確認'
  );

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly groupId: string
  ) {
    StatusId._values.push(this);
  }

  static fromStatusId(value: number): StatusId {
    const res = StatusId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return StatusId.DEFAULT;
    }
  }

  static values(): Array<number> {
    return StatusId._values.map((x) => x.value);
  }

  static uploadingToCompletedValues(): Array<number> {
    return [
      StatusId.UPLOADING.value,
      StatusId.ASSORT.value,
      StatusId.ASSORT_DONE.value,
      StatusId.FIRST_CHECK_PROCESSING.value,
      StatusId.FIRST_CHECK_DONE.value,
      StatusId.SECOND_CHECK_PROCESSING.value,
      StatusId.SECOND_CHECK_DONE.value,
      StatusId.ERROR.value,
      StatusId.ERROR_RECONFIRMATION.value,
    ];
  }

  static secondCheckDoneToCompletedValues(): Array<number> {
    return [StatusId.SECOND_CHECK_DONE.value];
  }

  static getDropdownOptions(): Array<StatusId> {
    if (this._valuesCopy === null) {
      this._valuesCopy = StatusId._values
        .filter((status) => status.value !== StatusId.DEFAULT.value) // DEFAULT を除外
        .map(
          (status) => new StatusId(status.value, status.text, status.groupId)
        );
    }
    return this._valuesCopy;
  }

  static getClassFromStatusId = (statusId: number): string => {
    if (statusId <= 20000) return 'base-status-class status-pre-analysis-class';
    if (statusId <= 50000) return 'base-status-class status-analyzing-class';
    if (statusId == 60000) return 'base-status-class success';
    if (statusId == 90000) return 'base-status-class error';
    return 'base-status-class warning';
  };

  static getStatusFromStatusId = (statusId: number): string => {
    if (statusId <= 20000) return 'before';
    if (statusId <= 50000) return 'analyzing';
    if (statusId == 60000) return 'complete';
    if (statusId == 90000) return 'impossible';
    return 'check';
  };
}
